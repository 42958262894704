import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { isOpenBoxNumTipState } from '@/stores/auth';
import { Box, Dialog, Drawer } from '@mui/material';
import { useRecoilState } from 'recoil';
import DrawerHeder from '../../Me/components/DrawerHeder';
import NumTipDrawerConten from './NumTipDrawerConten';

const NumTipDrawer: React.FC<any> = (props: any) => {
  const { __ } = useLang();
  const [openBoxTip, setOpenBoxTip] = useRecoilState(isOpenBoxNumTipState);
  const isMobile = useResponsive('down', 'md');

  const _onClose = () => {
    setOpenBoxTip(false);
  };

  if (isMobile) {
    return (
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={openBoxTip}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
        onClose={_onClose}
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
          },
        }}
      >
        <Box
          bgcolor="transparent"
          position="relative"
          style={{
            width: isMobile ? '100vw' : 410,
            overflowY: 'hidden',
          }}
          sx={{ bgcolor: 'card.main' }}
        >
          <DrawerHeder
            title={__('How to get the number of draws')}
            onClose={_onClose}
          />

          <NumTipDrawerConten />
        </Box>
      </Drawer>
    );
  }
  return (
    <Dialog
      open={openBoxTip}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
        },
      }}
      onClose={_onClose}
      aria-labelledby="sign-in"
    >
      <Box
        bgcolor="transparent"
        position="relative"
        style={{
          width: '400px',
          height: '80vh',
          overflowY: 'hidden',
        }}
        sx={{ bgcolor: 'card.main' }}
      >
        <DrawerHeder
          title={__('How to get the number of draws')}
          onClose={_onClose}
        />
        <NumTipDrawerConten />
      </Box>
    </Dialog>
  );
};

export default NumTipDrawer;
