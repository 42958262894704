import { StorageKeys } from '@/constants/keys';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Fingerprint2 from 'fingerprintjs2';
import { SnackbarUtils } from '../components/snackbar/SnackbarProvider';

// 新增device-id缓存逻辑 ==> 确保每次请求有device-id
const deviceID = localStorage.getItem(StorageKeys.deviceId);
const rememberIP = localStorage.getItem('rememberIP') || '';
const requestTime = localStorage.getItem('requestTime') || '';
const ignModeIp = sessionStorage.getItem('setIncognitoModeIPState');
let deviceprint: any = deviceID || undefined;

const isIncognitoMode = () => {
  return new Promise(function (resolve, reject) {
    // 尝试在localStorage中写入一个测试项
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      // 如果写入和删除操作都成功，则可以推断不在无痕模式下      resolve(false);
    } catch (e) {
      // 如果写入和删除操作失败，则可以推断在无痕模式下
      resolve(true);
    }
  });
};

const setFingerprint = () => {
  // deviceprint已存在缓存，则返回
  if (deviceprint) {
    return;
  }

  Fingerprint2.get(
    {
      // preprocessor: null,
      audio: {
        timeout: 1000,
        // On iOS 11, audio context can only be used in response to user interaction.
        // We require users to explicitly enable audio fingerprinting on iOS 11.
        // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
        excludeIOS11: true,
      },
      fonts: {
        swfContainerId: 'fingerprintjs2',
        swfPath: 'flash/compiled/FontList.swf',
        userDefinedFonts: [],
        extendedJsFonts: true,
      },
      screen: {
        // To ensure consistent fingerprints when users rotate their mobile devices
        detectScreenOrientation: true,
      },
      plugins: {
        sortPluginsFor: [/palemoon/i],
        excludeIE: false,
      },
      extraComponents: [
        {
          key: 'rememberIP',
          getData:function (done, options) {
              done(rememberIP || ignModeIp||'');
          }
        },{
          key: 'requestTime',
          getData:function (done, options) {
              done(requestTime || '');
          }
        },
      ],
      excludes: {
        // Unreliable on Windows, see https://github.com/Valve/fingerprintjs2/issues/375
        enumerateDevices: true,
        // devicePixelRatio depends on browser zoom, and it's impossible to detect browser zoom
        pixelRatio: true,
        // DNT depends on incognito mode for some browsers (Chrome) and it's impossible to detect incognito mode
        doNotTrack: true,
        // uses js fonts already
        fontsFlash: true,

        // #### Browser independent components

        userAgent: true,
        //no (most of the time)
        language: true,
        // no (but not supported by IE)
        hardwareConcurrency: true,

        sessionStorage: true,
        localStorage: true,
        indexedDb: true,
        addBehavior: true,
        openDatabase: true,
        plugins: true,
        adBlock: true,
        fonts: true,
        audio: true,

        // exclude screen
        screenResolution: true,
        availableScreenResolution: true,

        // (most of the time)
        //'canvas': true,
        //'webgl': true,
        //'fonts',
      },
      NOT_AVAILABLE: 'not available',
      ERROR: 'error',
      EXCLUDED: 'excluded',
    },
    (components) => {
      
      var values = components.map((component) => {
        return component.value;
      });
      deviceprint = Fingerprint2.x64hash128(values.join(''), 31);

      // 缓存 device-id
      if (deviceprint && typeof deviceprint === 'string') {
        localStorage.setItem(StorageKeys.deviceId, deviceprint);
      }
    },
  );
};

if ((window as any).requestIdleCallback) {
  (window as any).requestIdleCallback(setFingerprint);
} else {
  setTimeout(setFingerprint, 200);
}

export const handleRequestError = (error: any) => {
  const msg = error?.data?.message || error?.data?.msg;
  if (msg) {
    console.log(msg);
    SnackbarUtils.error(msg);
  }
};

// 登出
const logout = () => {
  // 需要登录权限的页面，则跳转到登录页
  // if (PRIVATE_PATH_LIST.includes(window.location.pathname)) {
  SnackbarUtils.warning('Login expired, please log in again');
  localStorage.setItem('access_token', '');
  // Router.push(PATH_PAGE.home);
  // window.location.href = '/?signin=1';
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
};

const Request = axios.create({
  timeout: 20 * 1000,
});

// 请求前拦截
export const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  // 请求时带 access_token 和 language
  const access_token = localStorage.getItem('access_token');
  if (access_token) {
    config.headers['Authorization'] = access_token;
  }
  if (deviceprint) {
    config.headers['uuid'] = deviceprint;
  }

  const language = localStorage.getItem('__language__') || 'pt';
  config.headers['Accept-Language'] = language;
  config.headers['times'] = Math.floor(Date.now() / 1000);
  config.headers['version'] = 1000;
  config.headers['sign'] = 'abcd';
  config.headers['tid'] = 1;
  config.headers['sid'] = '1';
  config.headers['lang'] = language;

  return config;
};

Request.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error),
);

// 响应后拦截
export const responseIntercepter = async (res: AxiosResponse) => {
  if (res?.status === 200) {
    const innerStatus = res.data?.status;
    if (innerStatus && innerStatus !== 1) {
      handleRequestError(res);
      return Promise.reject(res);
    }
    return Promise.resolve(res);
  } else if (res?.status === 401) {
    logout();
    return Promise.reject(res);
  } else {
    return Promise.reject(res);
  }
};

Request.interceptors.response.use(responseIntercepter, (error) => {
  if (error?.response?.status === 401) {
    logout();
  }
  return Promise.reject(error);
});

export default Request;
