import ICON_LOCK from '@/assets/icons/icon_lock.png';
import CommonSkeleton from '@/components/CommonSkeleton';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { isOpenVerifyAccountState } from '@/stores/auth';
import { ColorfulBg } from '@/styles';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {
  Box,
  Button,
  Dialog,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import MenusWeb from '../Me/components/MenusWeb';
import EditLoginPasswordPage from './EditLoginPassword';
import EditPayPasswordPage from './EditPayPassword';
import SetPayPasswordPage from './SetPayPassword';
import VerifyAccountDrawer from './VerifyAccountDrawer';

interface Props {}

const Setting: FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { __ } = useLang();

  const setOpenVerifyAccount = useSetRecoilState(isOpenVerifyAccountState);

  const [openEdit, setOpenEdit] = useBoolean();
  const [openLogin, setOpenLogin] = useBoolean();
  const [openSet, setOpenSet] = useBoolean();
  const [openLoginM, setOpenLoginM] = useBoolean();
  const [accountType, setAccountType] = useState<string>('phone');
  const isMobile = useResponsive('down', 'md');

  if (!user) {
    return <CommonSkeleton />;
  }
  console.log('user:', user);
  return (
    <Box>
      <Box
        mb={1}
        display={{ xs: 'none', md: 'flex' }}
        alignItems="center"
        height="48px"
      >
        <Typography variant="subtitle2" fontWeight={700}>
          {__('Setting')}
        </Typography>
      </Box>
      <Box
        sx={{
          border: { md: 'none' },
          bgcolor: { md: 'background.neutral' },
          borderRadius: '10px',
          p: { md: 3 },
        }}
        // py={2.5}
      >
        <Stack
          height={'44px'}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          display={'none'}
        >
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <img
              src={ICON_LOCK}
              alt="account"
              style={{ width: 20, height: 20 }}
            />
            <Typography>{__('资金密码')}</Typography>
          </Stack>
          <Button
            onClick={() => {
              if (user.pay_password === 1) {
                if (isMobile) {
                  navigate('/edit-payment');
                } else {
                  setOpenEdit.on();
                }
              } else {
                if (isMobile) {
                  navigate('/set-payment');
                } else {
                  setOpenSet.on();
                }
              }
            }}
            sx={{
              background: {
                md: ColorfulBg(),
              },
              borderRadius: '8px',
            }}
            color="info"
            variant="text"
            size="small"
          >
            {__(user.pay_password === 1 ? 'Edit' : 'Setting')}
          </Button>
        </Stack>
        <Stack
          sx={styles.itemBg}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Typography sx={{ color: 'text.secondary' }}>
              {__('Current Password')}
            </Typography>
          </Stack>
          <Button
            onClick={() => {
              if (isMobile) {
                // navigate('/edit-login');
                setOpenLoginM.on();
              } else {
                setOpenLogin.on();
              }
            }}
            sx={{
              background: ColorfulBg(),
              borderRadius: '8px',
              width: '104px',
              height: '40px',
            }}
            color="info"
            variant="text"
          >
            {__('Edit')}
          </Button>
        </Stack>
        <Stack
          sx={styles.itemBg}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Typography sx={{ color: 'text.secondary' }}>
              {__('Email')}
            </Typography>
          </Stack>
          {user?.email_bind ? (
            <Typography sx={{ color: 'text.primary' }}>
              {user?.email}
            </Typography>
          ) : (
            <Button
              onClick={() => {
                setOpenVerifyAccount('email');
              }}
              sx={{
                background: ColorfulBg(),
                borderRadius: '8px',
                width: '104px',
                height: '40px',
              }}
              color="info"
              variant="text"
            >
              {__('Verify')}
            </Button>
          )}
        </Stack>
        <Stack
          sx={styles.itemBg}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Typography sx={{ color: 'text.secondary' }}>
              {__('Phone')}
            </Typography>
          </Stack>
          {user?.phone_bind === 1 ? (
            <Typography sx={{ color: 'text.primary' }}>
              {user?.phone}
            </Typography>
          ) : (
            <Button
              onClick={() => {
                setOpenVerifyAccount('phone');
              }}
              sx={{
                background: ColorfulBg(),
                borderRadius: '8px',
                width: '104px',
                height: '40px',
              }}
              color="info"
              variant="text"
            >
              {__('Verify')}
            </Button>
          )}
        </Stack>
      </Box>

      <Dialog
        PaperProps={{
          sx: { width: '400px' },
        }}
        onClose={setOpenEdit.off}
        open={openEdit}
      >
        <EditPayPasswordPage showClose onSuccess={setOpenEdit.off} />
      </Dialog>
      <Dialog
        PaperProps={{
          sx: { width: '400px' },
        }}
        onClose={setOpenLogin.off}
        open={openLogin}
      >
        <EditLoginPasswordPage showClose onSuccess={setOpenLogin.off} />
      </Dialog>
      <Dialog
        PaperProps={{
          sx: { width: '400px' },
        }}
        onClose={setOpenSet.off}
        open={openSet}
      >
        <SetPayPasswordPage showClose onSuccess={setOpenSet.off} />
      </Dialog>
      <Drawer
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
        anchor={'bottom'}
        open={openLoginM}
        onClose={setOpenLoginM.off}
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
          },
        }}
      >
        <Box
          bgcolor="transparent"
          position="relative"
          style={{
            width: '100vw',
            overflowY: 'hidden',
          }}
          sx={{ bgcolor: 'card.main' }}
        >
          <EditLoginPasswordPage showClose onSuccess={setOpenLoginM.off} />
        </Box>
      </Drawer>
      <VerifyAccountDrawer />
    </Box>
  );
};

const SettingPage = () => {
  const navigate = useNavigate();
  const { __ } = useLang();

  return (
    <Box>
      <Box display={{ md: 'none' }}>
        <Box
          sx={{
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton color="info" onClick={() => navigate(-1)}>
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <Typography textAlign="center" variant="subtitle1" fontWeight={700}>
            {__('Setting')}
          </Typography>

          <Box width="40px"></Box>
        </Box>
      </Box>
      <Box py={{ xs: 1.5, md: 2 }}>
        <Stack direction="row" spacing={3}>
          <MenusWeb active="setting" />
          <Box flex="auto">
            <Setting />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

const styles = {
  itemBg: {
    bgcolor: 'card.main',
    height: '88px',
    borderRadius: '8px',
    px: '16px',
    mb: '16px',
  },
};
export default SettingPage;
