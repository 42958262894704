import { ScrollMessageDto } from '@/types';
import { Box } from '@mui/material';
import { memo } from 'react';
import Marquee from 'react-fast-marquee';
import { ScrollMessageItem } from './ScrollMessage';

export const ScrollMessageList = memo(
  (props: {
    list: Array<ScrollMessageDto>;
    delay?: number;
    background?: string;
    pt?: number;
    height?: string;
  }) => {
    return (
      <Box
        sx={{
          pt: props.pt ?? {
            xs: 0,
            md: 2,
          },
          px: { md: 2 },
          height: props.height ?? {
            xs: '40px',
            md: '52px',
          },
          width: '100%',
        }}
      >
        <Marquee gradient={false} speed={50} delay={props.delay ?? 0}>
          {props.list.map((item, index) => (
            <ScrollMessageItem
              key={`
              ${index}-${item.userId}-${item.receivedAmt}
              `}
              item={item}
              background={props.background}
            ></ScrollMessageItem>
          ))}
        </Marquee>
      </Box>
    );
  },
);

export const DepositScrollMessageList = memo(
  (props: { list: Array<ScrollMessageDto>; delay?: number }) => {
    return (
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Marquee gradient={false} speed={50} delay={props.delay ?? 0}>
          {props.list.map((item, index) => (
            <ScrollMessageItem
              key={`
              ${index}-${item.userId}-${item.receivedAmt}
              `}
              item={item}
            ></ScrollMessageItem>
          ))}
        </Marquee>
      </Box>
    );
  },
);
