export const en = {
  // 通用
  Save: 'Save',
  Success: 'Success',
  Successfully: 'Successfully',
  Edit: 'Edit',
  More: 'More',
  'Load More': 'Load More',
  'Nothing more': 'Nothing more',
  'No more': 'No more',
  Search: 'Search',
  Cancel: 'Cancel',
  Sure: 'Sure',
  Submit: 'Submit',
  No: 'No',
  Yes: 'Yes',
  Empty: 'Empty',
  Receive: 'Receive',
  // 登录
  '您还未绑定过账号，请先注册并自动绑定': `You haven't bound your account yet. Please register first and bind it automatically.`,
  登录: 'Sign In',
  Phone: 'Phone',
  Email: 'Email',
  'Please enter email': 'Please enter email',
  'Please enter phone number': 'Please enter phone number',
  'Send Code': 'Send Code',
  'Please enter verify code': 'Please enter verify code',
  'Enter Phone': 'Enter CPF phone',
  密码登录: 'Via Password',
  验证码登录: 'Via Code',
  'Forgot password?': 'Forgot password?',
  'Privacy Policy and Terms': `This site is subject to recaptcha and Google Privacy Policy and Terms Of Service apply.`,
  'Not registered? ': 'Not registered? ',
  'Join us': 'Join us',
  // 注册
  'Sign Up': 'Sign Up',
  密码不一致: 'Password inconsistent',
  'Enter new password': 'Enter new password',
  'Enter new password Again': 'Enter new password Again',
  // 重置密码
  'Forgot Password': 'Forgot Password',
  'Successfully reset password': 'Successfully reset password',
  'Can not receive code?': 'Can not receive code?',
  'Click to contact online customer service': `Click to contact online customer service`,
  // 历史记录
  History: 'History',
  资金变更: 'Funding changes',
  存款: 'Deposit',
  取款: 'Withdraw',
  游戏记录: 'Game Records',
  佣金: 'Commission',
  流水: 'Betting Volume',
  盈亏: 'Profit/Loss',
  条投注记录: '{n} betting records',
  '余额:': 'Balance:',
  // 我的页面
  '1 Day': '1 Day',
  '2 Day': '2 Day',
  '3 Day': '3 Day',
  '4 Day': '4 Day',
  '5 Day': '5 Day',
  '6 Day': '6 Day',
  '7 Day': '7 Day',
  每日签到: 'Daily Check-in',
  立即签到: 'check-in now',
  断更将从第1天开始领取: `The discontinued updates will be collected from the 1st day`,
  签到成功: 'Check-in successfully',
  'ID: ': 'ID: ',
  'My Wallet': 'My Wallet',
  提取: 'Withdraws',
  Account: 'Account',
  'Live Support': 'Live Support',
  Setting: 'Setting',
  'Sign Out': 'Sign Out',
  '是否确定退出？': 'Are you sure to exit?',
  'Sign out successfully': 'Sign out successfully',
  // VIP页面
  'Current Level': 'Current Level',
  完成以下任务即可升级到: 'Complete the following tasks to upgrade to:',
  打码量: 'Betting Volume',
  充值次数: 'Number of Deposits',
  次: '{n} times',
  充值金额: 'Deposit Amount',
  Privilege: 'Privilege',
  每日最大提款: 'Maximum Withdrawal Per Day',
  周礼金: 'Weekly Bonus',
  月礼金: 'Monthly Bonus',
  Bonus: 'Bonus',
  保级要求: 'Retention Requirements',
  保级充值金额: 'Retention Deposit Amount',
  到期: 'YYYY-MM-DD Expiration',
  保级天数: 'Retention Days',
  // 设置
  资金密码: 'Payment Password',
  'Set Payment Password': 'Set Payment Password',
  'set payment tips': `Only letters, numbers, and common symbols can be used. The minimumlength is 4 bits and the maximum length is 32 bits`,
  'Edit Payment Password': 'Edit Payment Password',
  'Enter current password': 'Enter current password',
  // 第三方账号绑定
  绑定成功: 'Bind successfully',
  绑定: 'Bind',
  解绑成功: 'Unbind successfully',
  解绑: 'Unbind',
  '是否确定解绑？': 'Are you sure to unbind?',
  // 充值
  moneyLimit: 'The value cannot be higher than {max} or lower than {min}',
  Deposit: 'Deposit',
  支付方式: 'Payment Method',
  请输入或选择存款金额: 'Please enter or select the deposit amount',
  立即存款: 'Deposit Now',
  // 游戏分类页面
  Providers: 'Providers',
  Popular: 'Popular',
  // 搜索页面
  'Please enter': 'Please enter',
  // 钱包
  Wallet: 'Wallet',
  钱包总额: 'Total Wallet',
  剩余打码金额: 'Remaining Betting Volume',
  充值: 'Deposit',
  // 游戏详情
  'Real Mode': 'Real Mode',
  'Are you sure to exit?': 'Are you sure to exit?',
  Recommended: 'Recommended',
  // 邀请
  Commissions: 'Commissions',
  Members: 'Members',
  Log: 'Log',
  'Copy to clipboard': 'Copy to clipboard',
  'Share to': 'Share to',
  'share link': 'share link',
  CommissionsMoney: 'Commissions of First Deposits(Current):R$ {n}',
  'Rule description': 'Rule description',
  Example: 'Example',
  Bets: 'Bets',
  Proportion: 'Proportion',
  Earn: 'Earn',
  rule1: `Your commission = commission of level 1 + commission of level 2 + commission of level 3`,
  rule2: `Commission of level 1 = (A1 cumulative bets + B1 cumulative bets...)x0.3%`,
  rule3: `Commission of level 2 = (A21+A22+B21+...+B22... all cumulative bets)x0.2%`,
  rule4: `Commission of level 3 = (A31+A32+B31+...+B32... all cumulative bets)x0.1%`,
  'Exemple:': 'Exemple:',
  rule5: 'Cumulative bets level 1 = 100,000',
  rule6: 'Cumulative bets level 2 = 200,000',
  rule7: 'Cumulative betslevel 3 = 200,000',
  rule8: 'Your earn: 100,000x0.3%+200,000x0.2%+200.000x0.1% = R$900',
  结束时间不能小于开始时间: 'End time cannot be less than start time',
  ID: 'ID',
  'Invited Date': 'Invited Date',
  Deposits: 'Deposits',
  'Vald Bets': 'Vald Bets',
  累计获得佣金: 'Cumulative commission earned',
  条数据: '{n} records in total',
  'Game Providers': 'Game Providers',
  // 首页-游戏分类
  fish: 'fish',
  hot: 'hot',
  like: 'like',
  live: 'live',
  recent: 'recent',
  recommend: 'recommend',
  slots: 'slots',
  // 回到顶部
  'Back to top': 'Back to top',
  // 页头
  'Log in': 'Log in',
  // 页脚
  Suggestion: 'Suggestion',
  'Help center': 'Help center',
  'Terms and conditions': 'Terms and conditions',
  'Privacy Policy': 'Privacy Policy',
  'Contact us': 'Contact us',
  copyright: '© 2024 {host} | All Rights Reserved.',
  // 提现
  '请绑定CPF后再选择提现。': 'Please bind CPF before selecting withdrawal.',
  'Safety Verification': 'Safety Verification',
  'Enter CPF': 'Enter CPF',
  'Enter You First Name': 'Enter You First Name',
  'Enter You Last Name': 'Enter You Last Name',
  Birthday: 'Birthday',
  绑定手机: 'Bind Phone',
  请输入资金密码: 'Please enter payment password',
  显示提现帐号: 'Show withdrawal account',
  全部提现: 'All Withdraw',
  填写资金密码: 'Enter payment password',
  提现: 'Withdraw',
  温馨提示: 'Tips',
  '您还没有设置资金密码，是否立即设置？': `You haven't set the payment password yet. Do you want to set it now?`,
  去设置: 'Go to set',
  gameName: 'Game Name',

  'Risk Warning': 'Risk Warning',
  'Risk Warning Content': `We will provide games with an adventure experience. To become a user of our website, you must be at least 18 years of age. We are not responsible for any violation of your local laws regarding online gambling. Please ensure that your gambling is controlled.`,

  nProviders: '{n} Providers',
  'Select deposit amount': 'Select deposit amount',
  'Already have an account?': 'Already have an account?',
  'Log in now': 'Log in now',
  displaying: '{x} records loaded',
  Information: 'Information',
  Promotions: 'Promotions',
  提款方式: 'Withdrawal Method',
  提款信息: 'Withdrawal Information',
  wallettip1: 'Accumulated bets',
  wallettip2: 'to unlock withdrawal',
  dailySignTitle1: 'Signed in for',
  dailySignTitle2: 'consecutive days',
  'Sign in': 'Sign in',
  'Signed in': 'Signed in',
  Today: 'Today',
  'Sign In Successfully': 'Sign In Successfully',
  'Rate of Rebate': 'Rate of Rebate',
  rebateTip: `The fund password is used for cash withdrawal, and can use letters, numbers, and commonly used symbols. The minimum length is 4 digits and the maximum length is 32 digits.`,
  messages: 'Messages',
  'Messages Detail': 'Messages Detail',
  'Winning Ranking': 'Winning Ranking',
  Rank: 'Rank',
  Player: 'Player',
  Profit: 'Profit',
  delFail: 'Delete failed, please try again',
  'Winning Records': 'Winning Records',
  'Invite Ranking': 'Invite Ranking',
  Record: 'Record',
  Time: 'Time',
  'Select A Channel': 'Select A Channel',
  'Select Coupon (n)': 'Select Coupon ({n})',
  Coupon: 'Coupon',
  Congratulations: 'Congratulations!',
  signSuccessTip: 'you signed in successfully and won the prize',
  signDay: '{n} Day',
  'Contact Platform': 'Contact Platform',
  Use: 'Use',
  'Expires in': 'Expires in {time}',
  Expired: 'Expired',
  register: 'Register',
  login: 'Login',
  'Register And Sign In': 'Register And Sign In',
  'Game Recommendations': 'Game Recommendations',
  'View All': 'View All',
  Play: 'Play',
  completeTask: '*Complete the above tasks to upgrade to ',
  'Relegation Task': 'Relegation Task',
  completedDate:
    'The relegation mission has been completed! The next task will start on {date}.',
  unCompletedDate:
    'If completed before {date}, the relegation will be successful, otherwise it will be downgraded.',
  登录密码: 'Login Password',
  'Edit Login Password': 'Edit Login Password',
  Reward: 'Reward',
  'Invitation Code (Optional)': 'Invitation Code (Optional)',
  'Como instalar?': 'Como instalar?',
  'account lock tip':
    'Temporarily suspend your account from all betting activitiesfor a set duration of time. Warning! once your account hasbeen suspended you will have to wait out the suspension orcontact support to revoke the suspension.',
  'Suspension Days': 'Suspension Days',
  'Current Password': 'Current Password',
  Verify: 'Verify',
  'Change Password': 'Change Password',
  'Phone Verification': 'Phone Verification',
  'Email Verification': 'Email Verification',
  'login tip':
    'Unregistered {type} will be directly registered and logged in for you after verification.',
  Transfer: 'Transfer',
  'Transfer tip': 'Transfer To The Account Balance',
  Amount: 'Amount',
  Message: 'Message',
  'Game Num': '{num}/{all} records loaded',
  'Enter your amount': 'Minimum: {min}, Maximum: {max}',
  'Mobile phone number': 'Mobile phone number',
  'email address': 'email address',
  'commission tip1': `Every new valid member you invite, you can earn R$[min] to [max].`,
  'commission tip1-0': `What is a valid member?`,
  'commission tip1-1': `1. Accumulated top-up of 20 reais or more.`,
  'commission tip1-2': `2. Accumulated bet 200 or above.`,
  'commission tip2': `When meeting the conditions of V2 and above, please contact your dedicated customer service to upgrade the level.`,
  'commission tip3': `After upgrading, you can receive commission rewards from second and third level team members.`,
  'Join the telegram group, there will be surprises every day': `Join the telegram group, there will be surprises every day`,
  'Join in': 'Join in',
  Spinner: 'Spinner',
  Casino: 'Casino',
  Invite: 'Invite',
  ME: 'ME',
  Recharge: 'Recarga',
  'Bet Amount': 'Valor da aposta',
  Level: 'NÍVEL',
  Depositor: `Depositante`,
  First: `Primeiro`,
  Second: `Segundo`,
  Third: `Terceiro`,
  Records: `Records`,
  Task: `Tarefas`,
  General: `Geral`,
  Settings: `Configurações`,
  绑定社交账号: `Vincular conta social`,
  '充值&提款': `Depósito e retirada`,
  'Enter your password': `Enter your password`,
  'Withdrawable Amount': 'Withdrawable Amount',
  'This site is subject to recaptcha and Google Privacy Policy and Terms Of Service apply.': `Este site está sujeito a recaptcha e a Política de Privacidade e os Termos de Serviço do Google se aplicam.`,
  'Withdrawal Fee': 'Withdrawal Fee',
  'The minimum withdrawal value is': 'The minimum withdrawal value is',
  'Remember account password': 'Remember account password',
  Start: 'Start',
  'Winning Record': 'Winning Record',
  'box num': 'You have [num] blind boxes',
  Rewards: 'Rewards',
  Lobby: 'Lobby',
  'invite Tip':
    'Invite friends to register and place bets, and each person will receive an invitation bonus of ',
  'Box Tip':
    'If you reach the designated VIP level by placing bets and recharging, you will get the number of draws. Different levels will result in different numbers of draws.',
  'The following data is updated every 10 minutes':
    'The following data is updated every 10 minutes',

  'lucky draw': 'lucky draw',
  'How to get the number of draws': 'How to get the number of draws',
  'Withdrawal fee': 'Withdrawal fee',
  'Rebate range': 'Rebate range',
  mTip1: '1. The subordinate has accumulated cooldowns of 10 or above.',
  mTip2: '2. The subordinate has accumulated bets of 100 or above.',
  'please choose withdraws type': 'please choose withdraws type',
  withTip: 'Minimum:30,Maximum:20000',
  vipTip1:
    '1.Every deposit, everyone needs to go through a bet before it can be converted into a Withdrawable Balance.Only bets that generate wins and losses can obtain the Withdrawable Balance.',
  vipTip2:
    '2.All cancelled, rejected and tied bets cannot obtain the Withdrawable Balance.The maximum amount that can be withdrawn does not exceed the Account Balance.',
  vipTip3:
    '3.The maximum amount that can be withdrawn does not exceed the Account Balance.',
  // 1.2 新增
  invite: '',
  '首次充值（只有一次机会）': 'First Deposit（There is only one chance）',
  首充福利: 'First deposit benefits: ',
  首次充值可额外获得xx奖金:
    'You can get an additional {xx} bonus for the first deposit. There is one and only one chance. Only one of the options can be selected.',
  paster: 'paster',
  tabInvite: 'Invite',
  tabStatistics: 'Statistics',
  tabLog: 'Log',
  'Exclusive Invitation Link': 'Exclusive Invitation Link',
  Copy: 'Copy',
  'Rewards&Commissions': 'Rewards&Commissions',
  'Open treasure chest': 'Open treasure chest',
  'Win commission': 'Win commission',
  'Mission conditions': 'Mission conditions',
  'recharge reaches': 'recharge reaches',
  'Cumulative bets reached': 'Cumulative bets reached',
  Historical: 'Historical',
  'Total Data': 'Total Data',
  'Total Cumulative Prize Money': 'Total Cumulative Prize Money',
  'Treasure Chest': 'Treasure Chest',
  'Opening Data': 'Opening Data',
  'Completed invitation conditions': 'Completed invitation conditions',
  'Bounty obtained': 'Bounty obtained',
  Compeleted: 'Compeleted',
  'In Progress': 'In Progress',
  'Accumulated recharge': 'Accumulated recharge',
  'Cumulative bets': 'Cumulative bets',
  'Data For Inviting': 'Data For Inviting',
  'New Players': 'New Players',
  'Numbers of new members': 'Números de novos membros',
  'Valid direct invitations': 'Valid direct invitations',
  'same tip':
    'You have already participated in the event. Accountsregistered with the same device will not be able to receiverewards.',
  'No Reward Required': 'No Reward Required',
  boxTip1:
    '1.If the new player you invite completes the specified task, you can open the treasure chest and receive a bounty.',
  boxTip2: '2.Invitation data is updated every 10 minutes',
  Expand: 'Expand',
  Collapse: 'Collapse',
  'For every new active member you invite, you get a percentage of the bonus.':
    'For every new active member you invite, you get a percentage of the bonus.',
  // 下面未翻译
  Conpon: 'Conpon',
  'Download APP': 'Download APP',
  'Histórico Efetivo da Primeira Carga': 'First direct recharge',
  'Histórico de convites': 'Invitation history',
  'Valor de recarga da equipe': 'Team Reload Value',
  'Apostas da equipe': 'Team bets',
  pricayTip:
    'Reaching the location. I confirm that I am 18 years old and I have read Terms of Service',
  pricayLink: 'Termos de servico',
  'Receba promocoes por e-m': 'Receba promocoes por e-m',
  'Deposit Num': 'Deposit {now} / {sum}',
  'vip level tip1':
    'Your deposit last month was insufficient, VIP privileges were frozen.',
  'vip level tip2':
    'Completed R$ {now} deposit this month, VIP privileges will be restored.',
  Frozen: 'Frozen',
  'Login or Register': 'Login or Register',
  rentouMoney: 'R$[money]',
  maxVipTip: '*Reached the highest level so far',
  'Incorrect email format': 'Incorrect email format',
  'Redemption failed': 'Redemption failed',
  'Redeem when conditions are met': 'Redeem when conditions are met',
  'Go To Redeem': 'Go To Redeem',
  'Redemption instructions': 'Redemption instructions',
  codeTip11: 'The redeemable quantity is limited, limited to ',
  codeTip12: ' copies, first come, first served;',
  codeTip2: 'Users must meet the redemption conditions before they can redeem;',
  codeTip3: 'Each person is only allowed to redeem once;',
  'Redeem Now': 'Redeem Now',
  'Gift code': 'Gift code',
  'Enter your code': 'Enter your code',
  'Copied successfully': 'Copied successfully',
  'Amount Recharge': 'Amount Recharge',
  'Package Purchase': 'Package Purchase',
  'Total received': 'Total received',
  'Total reception': 'Total reception',
  'Rule details': 'Rule details',
  Restricted: 'Restricted',
  'Daily reception': 'Daily reception',
  'First reception': 'First reception',
  Only: 'Only',
  'Comunidade oficial recomendada': 'Comunidade oficial recomendada',
  'As informações mais recentes e rápidas':
    'E receba promoções exclusivas rapidamente',
  day: 'day',
  Received: 'Received',
  Uncollected: 'Uncollected',
  'Already obtained': 'Already obtained',
  Discontinue: 'Discontinue',
  'Temporarily dismantle': 'Temporarily dismantle',
  'Get rewarded': 'Get rewarded',
};
