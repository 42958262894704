import useResponsive from '@/hooks/useResponsive';
import { mobileHeaderbarHeight, webHeaderbarHeight } from '@/theme';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
interface Props {
  onBack?: () => void;
  px?: number;
}

const BackHeader: React.FC<Props> = (props: Props) => {
  return (
    <Stack px={props.px || 0} direction="row" alignItems="center">
      <Button
        color="info"
        onClick={props.onBack}
        startIcon={<ArrowBackIosNewRoundedIcon />}
      >
        Back
      </Button>
    </Stack>
  );
};

export const PageHeader = (props: {
  onBack?: () => void;
  title: string;
  rightIcon?: React.ReactNode;
  onClickRight?: () => void;
}) => {
  const isMobile = useResponsive('down', 'md');
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  if (isMobile) {
    return (
      <AppBar
        position="fixed"
        sx={{
          top: matchUpMd ? webHeaderbarHeight : mobileHeaderbarHeight,
          bgcolor: 'background.default',
          boxShadow: 'none',
        }}
      >
        <Toolbar sx={{ minHeight: '44px !important' }}>
          <Stack
            px={1.5}
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: '44px' }}
          >
            <Box
              width="44px"
              height="44px"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ml: '-12px',
              }}
            >
              <IconButton color="info" onClick={props.onBack}>
                <ArrowBackIosNewRoundedIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Box>
            <Typography variant="body1" fontWeight={700}>
              {props.title}
            </Typography>
            <Box width="44px" height="44px">
              <IconButton color="info" onClick={props.onClickRight}>
                {props.rightIcon}
              </IconButton>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
    );
  } else {
    return (
      <Stack px={1.5} width="100%" direction="row" alignItems="center">
        <Typography variant="subtitle1" fontWeight={700}>
          {props.title}
        </Typography>
      </Stack>
    );
  }
};

export default BackHeader;
