import ICON_CLOSE from '@/assets/icons/ic_black_close.png';
import { useAuthJump } from '@/hooks/useAuthJump';
import useResponsive from '@/hooks/useResponsive';
import { diaLogBannerList, isShowDaLogBanner } from '@/stores/config';
import { IBannerItem } from '@/types/config';
import { Box, Dialog, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

export const Media5Dialog = () => {
  const media5 = useRecoilValue(diaLogBannerList);
  const isMobile = useResponsive('down', 'md');
  const { authJump } = useAuthJump();
  const [isShow, setIsShowDaLogBanner] = useRecoilState(isShowDaLogBanner);
  const [open, setOpen] = useState<boolean>(media5.length > 0 && !isShow);

  const onCloseDialog = () => {
    setOpen(false);
    setIsShowDaLogBanner(true);
  };

  const handleClick = (data: IBannerItem) => {
    const success = authJump(data);
    if (!success) {
      return;
    }
    onCloseDialog();
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
        },
      }}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer - 1,
      }}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="sign-in"
    >
      <Box
        sx={{
          position: 'relative',
          borderRadius: isMobile ? '8px' : '0',
        }}
      >
        <Swiper
          centeredSlides={true}
          slidesPerView={1}
          autoplay={{
            delay: 2000,
            reverseDirection: false,
          }}
          spaceBetween={16}
          loop
          pagination={false}
          modules={[Autoplay]}
        >
          {media5?.map((item, idx) => {
            return (
              <SwiperSlide key={idx}>
                <img
                  style={{
                    objectFit: 'fill',
                  }}
                  src={item?.pic}
                  alt={item?.title}
                  onClick={() => handleClick(item)}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <Stack
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            width: '28px',
            height: '28px',
            borderRadius: '14px',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: alpha('#000000', 0.4),
            zIndex: 100,
            cursor: 'pointer',
          }}
          onClick={() => {
            onCloseDialog();
          }}
        >
          <img
            src={ICON_CLOSE}
            alt=""
            style={{ width: '28px', height: '28px', objectFit: 'cover' }}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};
