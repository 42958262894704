import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { Box, Dialog, Drawer } from '@mui/material';
import DrawerHeder from '../../Me/components/DrawerHeder';
import CardRuleDrawerConten from './CardRuleDrawerConten';

interface Props {
  open: boolean;
  onClose: () => void;
  ruleTxt: String;
}

const CardRuleDrawer: React.FC<any> = (props: Props) => {
  const { __ } = useLang();
  const isMobile = useResponsive('down', 'md');

  if (isMobile) {
    return (
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={props.open}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
        onClose={props.onClose}
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
          },
        }}
      >
        <Box
          bgcolor="transparent"
          position="relative"
          style={{
            width: isMobile ? '100vw' : 410,
            overflowY: 'hidden',
          }}
          sx={{ bgcolor: 'card.main' }}
        >
          <DrawerHeder
            title={__('How to get the number of draws')}
            onClose={props.onClose}
          />

          <CardRuleDrawerConten ruleTxt={props.ruleTxt} />
        </Box>
      </Drawer>
    );
  }
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
        },
      }}
      onClose={props.onClose}
      aria-labelledby="sign-in"
    >
      <Box
        bgcolor="transparent"
        position="relative"
        style={{
          width: '400px',
          height: '80vh',
          overflowY: 'hidden',
        }}
        sx={{ bgcolor: 'card.main' }}
      >
        <DrawerHeder
          title={__('How to get the number of draws')}
          onClose={props.onClose}
        />
        <CardRuleDrawerConten ruleTxt={props.ruleTxt} />
      </Box>
    </Dialog>
  );
};

export default CardRuleDrawer;
