import IMAGE_TOTAL_COIN from '@/assets/invitebox/total-coin-bg.png';
import { useAgentSummary } from '@/hooks/useAgentSummary';
import useLang from '@/hooks/useLang';
import { Box, Grid, Stack, Typography } from '@mui/material';

export const TabTotalData = () => {
  const { __ } = useLang();
  const { data, isLoading } = useAgentSummary();

  return (
    <Box px={1.5} pt={1} pb={{ xs: 2, md: 1.5 }}>
      <Grid container spacing={1} columns={24}>
        <Grid item xs={24}>
          <TotalDataCard
            label={__('Histórico Efetivo da Primeira Carga')}
            value={data?.totalRechargedCount ?? 0}
            bgColor="#313D4D"
          />
        </Grid>
        <Grid item xs={24}>
          <TotalDataCard
            label={__('Histórico de convites')}
            value={data?.totalCount ?? 0}
            bgColor="#586982"
          />
        </Grid>
        <Grid item xs={24}>
          <TotalDataCard
            label={__('Valor de recarga da equipe')}
            // {props.data?.rechargeAmt ?? 0}
            value={data?.rechargeAmt ?? 0}
            bgColor="#313D4D"
          />
        </Grid>
        <Grid item xs={24}>
          <TotalDataCard
            label={__('Apostas da equipe')}
            value={data?.orderAmt ?? 0}
            bgColor="#586982"
          />
        </Grid>
        <Grid item xs={24}>
          <Box
            sx={{
              p: 2,
              borderRadius: 1,
              bgcolor: '#313D4D',
              backgroundImage: `url(${IMAGE_TOTAL_COIN})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto 85%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography mr={0.5} variant="subtitle2" color="text.secondary">
                R$
              </Typography>
              <Typography variant="h1">{data?.totalAgentAmt ?? 0}</Typography>
            </Box>
            <Typography mt={1} variant="body1" color="text.secondary">
              {__('Total Cumulative Prize Money')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const TotalDataCard = ({
  label,
  value,
  bgColor,
}: {
  label: string;
  value: number;
  bgColor: string;
}) => {
  return (
    <Box
      sx={{
        p: 2,
        borderRadius: 1,
        bgcolor: bgColor,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" color="text.secondary">
          {label}
        </Typography>
        <Typography variant="h2">{value}</Typography>
      </Stack>
    </Box>
  );
};
