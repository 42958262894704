import Box4 from '@/assets/invitebox/box-disable.png';
import Box2 from '@/assets/invitebox/box-openable.png';
import Box1 from '@/assets/invitebox/box-opened.png';
import Box3 from '@/assets/invitebox/box.png';
import Arrow2 from '@/assets/invitebox/icon-arrow-gold.png';
import Arrow1 from '@/assets/invitebox/icon-arrow-sliver.png';
import Arrow3 from '@/assets/invitebox/icon-arrow.png';
import ARROWDOWN from '@/assets/invitebox/icon_doubleDown.svg';
import ARROWUP from '@/assets/invitebox/icon_doubleUp.svg';
import Center from '@/components/center';
import EmptyData from '@/components/EmptyData';
import { BoxDto, BoxStatus } from '@/hooks/useActivity';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import { useWidth } from '@/hooks/useResponsive';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

interface Props {
  list: Array<BoxDto>;
  // 已邀请
  inviteCount: number;
  onOpen: (ruleKey: number) => void;
}

const countInRowMap = {
  xs: 4,
  sm: 6,
  md: 8,
  lg: 10,
  xl: 10,
};

export const InviteBoxList = (props: Props) => {
  const width = useWidth();
  const { __ } = useLang();
  const [isExpend, setIsExpend] = useBoolean(false);
  const countInRow = countInRowMap[width];

  // 转换为二维数组，每行4个
  const boxList = useMemo(() => {
    const showList = isExpend ? props.list : props.list.slice(0, 12);

    return showList.reduce((acc, cur, index) => {
      const rowIndex = Math.floor(index / countInRow);
      if (!acc[rowIndex]) {
        acc.push([]);
      }
      acc[rowIndex].push(cur);
      return acc;
    }, [] as BoxDto[][]);
  }, [props.list, countInRow, isExpend]);

  if (!props.list.length) {
    return (
      <Center p={2}>
        <EmptyData />
      </Center>
    );
  }

  return (
    <Box mt={2}>
      <Box
        sx={{
          bgcolor: '#212933',
          borderRadius: 1,
          pt: 0,
          pb: { xs: 2, md: 4 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {boxList.map((row, rowIndex) => {
            const isRightDirection = rowIndex % 2 === 0;
            return (
              <Box
                key={rowIndex}
                sx={{
                  display: 'flex',
                  justifyContent: isRightDirection ? 'flex-start' : 'flex-end',
                  width: '100%',
                  '&:not(:last-child)': {
                    marginBottom: '24px',
                  },
                }}
              >
                {row.map((box, boxIndex) => {
                  const isLastBox =
                    rowIndex === boxList.length - 1 &&
                    boxIndex === row.length - 1;

                  const isLastInRow = boxIndex === row.length - 1;

                  return (
                    <Box
                      key={boxIndex}
                      sx={{
                        width: Math.floor(100 / countInRow) + '%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        order:
                          rowIndex % 2 === 0
                            ? boxIndex
                            : countInRow - 1 - boxIndex,
                        position: 'relative',
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        fontStyle="italic"
                      >
                        R${box.money}
                      </Typography>
                      <InviteBox
                        status={box.status}
                        onOpen={() => {
                          props.onOpen(box.ruleKey);
                        }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        <PeopleAltRoundedIcon sx={{ fontSize: '14px' }} />
                        <Typography variant="body2" ml="2px">
                          {Math.min(props.inviteCount, box.targetInvite)}/
                          {box.targetInvite}
                        </Typography>
                      </Box>

                      {/* 箭头垂直水平居中 */}
                      {!isLastBox && (
                        <Box
                          sx={
                            isLastInRow
                              ? {
                                  position: 'absolute',
                                  top: '100%',
                                  left: '50%',
                                  transform:
                                    'translate(-50%, 2px) rotate(90deg)',
                                  width: '16px',
                                  height: '16px',
                                }
                              : isRightDirection
                              ? {
                                  position: 'absolute',
                                  top: '50%',
                                  left: '100%',
                                  transform: 'translate(-50%, -50%)',
                                  width: '16px',
                                  height: '16px',
                                }
                              : {
                                  position: 'absolute',
                                  top: '50%',
                                  right: '100%',
                                  transform:
                                    'translate(50%, -50%) rotate(180deg)',
                                  width: '16px',
                                  height: '16px',
                                }
                          }
                        >
                          <BoxArrow status={box.status} />
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            );
          })}
          {props.list.length > 12 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
              onClick={setIsExpend.toggle}
            >
              <Typography>
                {isExpend ? __('Collapse') : __('Expand')}
              </Typography>
              <img
                src={isExpend ? ARROWUP : ARROWDOWN}
                alt=""
                style={{
                  width: '16px',
                  height: '16px',
                  marginLeft: '6px',
                }}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const InviteBox = ({
  status,
  onOpen,
}: {
  status: BoxStatus;
  onOpen: () => void;
}) => {
  let img = Box1;
  switch (status) {
    case BoxStatus.已开启:
      img = Box1;
      break;
    case BoxStatus.待开启:
      img = Box2;
      break;
    case BoxStatus.进行中:
      img = Box3;
      break;
    default:
      img = Box4;
  }

  return (
    <img
      src={img}
      alt="box"
      onClick={() => {
        if (status === BoxStatus.待开启) {
          onOpen();
        }
      }}
      style={{
        width: '48px',
        height: '48px',
      }}
    />
  );
};

const BoxArrow = ({ status }: { status: BoxStatus }) => {
  let img = Box1;
  switch (status) {
    case BoxStatus.已开启:
      img = Arrow1;
      break;
    case BoxStatus.待开启:
      img = Arrow2;
      break;
    case BoxStatus.进行中:
      img = Arrow3;
      break;
    default:
      img = Arrow3;
  }

  return (
    <img
      src={img}
      alt="arrow"
      style={{
        width: '16px',
        height: '16px',
      }}
    />
  );
};
