import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { isOpenWithdrawerValidState } from '@/stores/auth';
import { Box, Dialog, SwipeableDrawer } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import DrawerHeder from '../../pages/Me/components/DrawerHeder';
import WithdrawValidConten from './WithdrawValidConten';

interface Props {}

/**
 * 体现安全验证
 *
 * @param {Props} props
 * @return {*}
 */
const WithdrawValidDrawer = (props: Props) => {
  const { __ } = useLang();
  const [openWithdrawValid, setOpenWithdrawValid] = useRecoilState(
    isOpenWithdrawerValidState,
  );
  const isMobile = useResponsive('down', 'md');
  const { getWallet } = useAuth();

  useEffect(() => {
    if (openWithdrawValid) {
      getWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openWithdrawValid]);

  const _onClose = () => {
    setOpenWithdrawValid(false);
  };
  if (isMobile) {
    return (
      <SwipeableDrawer
        open={openWithdrawValid}
        onClose={_onClose}
        onOpen={() => setOpenWithdrawValid(true)}
        anchor={'bottom'}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
        }}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
      >
        <Box
          pb={2}
          sx={{ borderRadius: '20px 20px 0px 0px', bgcolor: 'card.main' }}
        >
          <DrawerHeder title={__('提取')} onClose={_onClose} />
          <WithdrawValidConten />
        </Box>
      </SwipeableDrawer>
    );
  }
  return (
    <Dialog
      open={openWithdrawValid}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
        },
      }}
      onClose={_onClose}
      aria-labelledby="sign-in"
    >
      <Box bgcolor="card.main" pb={2}>
        <DrawerHeder title={__('提取')} onClose={_onClose} />
        <WithdrawValidConten />
      </Box>
    </Dialog>
  );
};

export default WithdrawValidDrawer;
