import DipositDialog from '@/components/deposit/DepositDialog';
import useAuth from '@/hooks/useAuth';
import VipDialog from '@/pages/Vip/VipDialog';
import services from '@/services';
import { isFetchingUserInfo, isLoginState, userInfoState } from '@/stores/auth';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Heartbeat from './Heartbeat';
import ForgetPwModal from './modal/ForgetPwModal';
import LRModal from './modal/LRModal';
import SigninModal from './modal/SigninModal';
import SignupModal from './modal/SignupModal';

interface Props {}

const Auth: React.FC<Props> = (props: Props) => {
  const [isLogin, setIsLogin] = useRecoilState(isLoginState);
  const setUserInfo = useSetRecoilState(userInfoState);
  const setFetchingUserInfo = useSetRecoilState(isFetchingUserInfo);
  const { getWallet } = useAuth();

  useEffect(() => {
    const init = async () => {
      const token = window.localStorage.getItem('access_token');
      // 没有token,未登录
      if (!token) {
        setIsLogin(false);
        return;
      }
      // 存在token，调用用户信息
      try {
        setFetchingUserInfo(true);
        const { data } = await services.auth.getUserInfo();
        if (data?.data) {
          setUserInfo(data.data);
          setIsLogin(true);
          // 刷新余额
          // 不是跳转玩游戏页面才刷新
          if (window.location.pathname != '/play') {
            getWallet();
          }
        }
      } catch (error: any) {
        console.log(error);
      } finally {
        setFetchingUserInfo(false);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <LRModal />
      <SigninModal />
      <SignupModal />
      <ForgetPwModal />
      {/* <UserMenuDrawer /> */}

      {isLogin && (
        <>
          <VipDialog />
          <DipositDialog />
          <Heartbeat />
        </>
      )}
    </Box>
  );
};

export default Auth;
