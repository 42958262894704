import bonus_bg from '@/assets/images/bonusOff.png';
import bonus_bg_on from '@/assets/images/bonusOn.png';
import icon_bonus from '@/assets/images/icon_bonus.png';
import icon_invite from '@/assets/images/icon_invite.png';
import invite_bg from '@/assets/images/invite_bg.png';
import invite_bg_on from '@/assets/images/invite_bg_on.png';
import Iconfont from '@/components/Iconfont';
import useAuth from '@/hooks/useAuth';
import { useHomeTabs } from '@/hooks/useHomeTabs';
import {
  isOpenLRModalState,
  isOpenVipDialogState,
  LRModalInitalPage,
} from '@/stores/auth';
import { supportListState } from '@/stores/config';
import { hideScrollbarY } from '@/utils/cssStyles';
import { Divider, List, ListItem, ListItemButton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

interface IMenuItem {
  name: string;
  path: string;
  icon: string;
  iconImg?: boolean;
  bgImg?: string;
  onBgImg?: string;
  divider?: boolean;
  isPrivate?: boolean;
}

const MENU_LIST: IMenuItem[] = [
  {
    name: 'Bonus',
    path: '/promotions',
    icon: icon_bonus,
    bgImg: bonus_bg,
    onBgImg: bonus_bg_on,
    isPrivate: true,
  },
  // {
  //   name: 'Spinner',
  //   path: '/lucky',
  //   icon: icon_spinner,
  //   bgImg: spinner_bg,
  //   onBgImg: spinner_bg_on,
  //   isPrivate: true,
  // },
  // {
  //   name: 'VIP',
  //   path: '/vip',
  //   icon: icon_vip,
  //   bgImg: vip_bg,
  //   onBgImg: vip_bg_on,
  //   isPrivate: true,
  // },
  {
    name: 'Invite',
    path: '/commissions',
    icon: icon_invite,
    bgImg: invite_bg,
    onBgImg: invite_bg_on,
    isPrivate: true,
  },
  // {
  //   name: 'Popular Games',
  //   path: '/popular-games',
  //   icon: 'icon_popularGames',
  // },
  // {
  //   name: 'Live Casino',
  //   path: '/live-casino',
  //   icon: 'icon_liveCasino',
  // },
  // {
  //   name: 'Slots',
  //   path: '/slots',
  //   icon: 'icon_slots',
  // },
  // {
  //   name: 'New Gamers',
  //   path: '/new-games',
  //   icon: 'icon_newGamers',
  // },
  // {
  //   name: 'Game Providers',
  //   path: '/game-providers',
  //   icon: 'icon_gameProviders',
  // },
];

const ActiveMenuItem = (props: {
  name: string;
  icon: string;
  iconImg?: boolean;
}) => {
  return (
    <ListItem
      sx={{
        transition: '0.3',
        mb: 0.5,
        height: '48px',
        borderRadius: '8px',
        bgcolor: 'primary.main',
        textTransform: 'capitalize',
      }}
    >
      {props.iconImg ? (
        <img
          src={props.icon}
          alt=""
          width={24}
          height={24}
          style={{ marginRight: 8 }}
        />
      ) : (
        <Iconfont name={props.icon} sx={{ mr: 2 }} />
      )}
      {props.name}
    </ListItem>
  );
};

const ImgBgMenuItem = (props: {
  menu: IMenuItem;
  isAcitve: Boolean;
  handleClick: Function;
}) => {
  const { menu, handleClick, isAcitve } = props;
  if (isAcitve) {
    return (
      <ListItem
        sx={{
          transition: '0.3',
          mb: 0.5,
          height: '64px',
          borderRadius: '8px',
          backgroundImage: `url(${isAcitve ? menu.onBgImg : menu.bgImg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <img
          src={menu.icon}
          alt=""
          style={{ width: '40px', height: '40px', marginRight: 2 }}
        />
        {menu.name}
      </ListItem>
    );
  }
  return (
    <ListItemButton
      key={menu.name}
      sx={{
        color: 'text.secondary',
        transition: '0.3',
        mb: 0.5,
        height: '64px',
        borderRadius: '8px',
        backgroundImage: `url(${menu.bgImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={() => handleClick(menu)}
    >
      <img
        src={menu.icon}
        alt=""
        style={{ width: '40px', height: '40px', marginRight: 2 }}
      />
      {menu.name}
    </ListItemButton>
  );
};

const MenuList = () => {
  const [activeMenu, setActiveMenu] = useState<IMenuItem | null>(null);
  const navigate = useNavigate();
  const { tabList } = useHomeTabs();
  const supportListValue = useRecoilValue(supportListState);

  const menuList: IMenuItem[] = useMemo(() => {
    const res = tabList
      .filter((item) => !['Lobby', 'Salão'].includes(item.title))
      .map((menu) => {
        return {
          name: menu.title,
          path: `/category?type=${menu.title}`,
          icon: menu.image ?? menu.image_i,
          iconImg: true,
        };
      });
    return res.length ? [{ divider: true } as IMenuItem, ...res] : [];
  }, [tabList]);

  const otherMenuList: IMenuItem[] = useMemo(() => {
    const res = supportListValue.map((menu) => {
      return {
        name: menu.name,
        path: menu.url,
        icon: menu.qr,
        iconImg: true,
      };
    });

    return res.length ? [{ divider: true } as IMenuItem, ...res] : [];
  }, [supportListValue]);

  const setIsOpenVip = useSetRecoilState(isOpenVipDialogState);
  const location = useLocation();
  const setInitalPage = useSetRecoilState(LRModalInitalPage);
  const setOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const { isLogin } = useAuth();

  const handleClick = (item: IMenuItem) => {
    if (item.isPrivate && !isLogin) {
      setInitalPage(0);
      setOpenLRModal(true);
      return;
    }

    if (item.name === 'VIP') {
      setIsOpenVip(true);
    } else {
      if (item?.path?.startsWith('http')) {
        window.open(item.path, '_blank');
      } else {
        navigate(item.path);
      }
    }
  };

  useEffect(() => {
    const fullPath = location.pathname + location.search;
    let isMatch = false;
    [...MENU_LIST, ...menuList].forEach((item) => {
      if (item.divider) return;

      if (
        item.path === fullPath ||
        item.path === location.pathname ||
        item.path === decodeURIComponent(fullPath)
      ) {
        setActiveMenu(item);
        isMatch = true;
      }
    });

    if (!isMatch) {
      setActiveMenu(null);
    }
  }, [location, menuList]);

  return (
    <List
      sx={{
        height: 'calc(100vh - 168px)',
        ...hideScrollbarY,
      }}
    >
      {[...MENU_LIST, ...menuList, ...otherMenuList].map((menu, index) => {
        if (menu.divider) {
          return <Divider sx={{ my: 1 }} key={`divider-${index}`} />;
        }
        if (menu.isPrivate) {
          return (
            <ImgBgMenuItem
              menu={menu}
              key={menu.name}
              handleClick={handleClick}
              isAcitve={menu.name === activeMenu?.name}
            />
          );
        }
        if (menu.name === activeMenu?.name) {
          return (
            <ActiveMenuItem
              name={menu.name}
              icon={menu.icon}
              key={menu.name}
              iconImg={menu.iconImg}
            />
          );
        } else {
          return (
            <ListItemButton
              key={menu.name}
              sx={{
                color: 'text.secondary',
                height: '48px',
                transition: '0.3',
                mb: 0.5,
                borderRadius: '8px',
                ':hover': {
                  bgcolor: 'primary.main',
                },
                textTransform: 'capitalize',
              }}
              onClick={() => handleClick(menu)}
            >
              {menu.iconImg ? (
                <img
                  src={menu.icon}
                  alt=""
                  width={24}
                  height={24}
                  style={{ marginRight: 8 }}
                />
              ) : (
                <Iconfont name={menu.icon} sx={{ mr: 1 }} />
              )}
              {menu.name}
            </ListItemButton>
          );
        }
      })}
    </List>
  );
};

export default MenuList;
