import services from '@/services';
import { weekCardActivityState } from '@/stores/config';
import { ActivityType, I18nValue } from '@/types';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

export interface weekCardDto {
  id: string;
  type: number;
  activityBlurb: I18nValue;
  activityName:I18nValue;
  activityTripTimes: string;
  userTripTimes: string;
  title: I18nValue;
  userActivityState: number;
  startTime:number;
  endTime:number;
  activityRulesList: Array<{
    rulePrizeAmt: number;
    ruleTargetAmt:number;
    firstReturnAmt:number;
    avgReturnAmt:number;
    ruleState:number;
  }>;
  showLevel:number;
}

export const useWeekCardBonus = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [weekCards, setWeekCards] = useRecoilState(weekCardActivityState);
  useEffect(() => {
    const fetchData = async () => {
      const res = await services.config.getActivityListV2(
        ActivityType.周卡,
      );
      
      try {
        setWeekCards(res||[]);
      } catch (error) {
        setWeekCards([]);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return {
    loading,
    weekCards,
  };
};
