import IMAGE_ACTIVE_LEFT from '@/assets/invitebox/tab-active-left.png';
import IMAGE_ACTIVE_RIGHT from '@/assets/invitebox/tab-active-right.png';
import { ActivityDto } from '@/hooks/useActivity';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import { homeTab } from '@/stores/config';
import { getTabBg } from '@/styles';
import { HomeTab, InviteEventsInfoDto } from '@/types';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { OpenBoxTab } from './OpenBoxTab';
import { WinBoxTab } from './WinBoxTab';
interface Props {
  info: InviteEventsInfoDto | null;
  detail: ActivityDto | null;
  onRefresh: () => void;
}

const tabs = ['Open', 'Win'];

export const OpenAndWinBox = (props: Props) => {
  const { detail } = props;
  const [tab, setTab] = useState<string>('Open');
  const isMobile = useResponsive('down', 'md');
  const homeTabValue = useRecoilValue(homeTab);

  useEffect(() => {
    if (homeTabValue === HomeTab.Invite) {
      setTab('Open');
    }
  }, [homeTabValue]);
  return (
    <Box px={2} mt={{ xs: 2, md: 3 }}>
      <Box
        sx={{
          bgcolor: '#212933',
          borderRadius: 1,
        }}
      >
        {/* {isMobile ? (
          <MobileTab tab={tab} setTab={setTab} />
        ) : (
        )} */}
        <Tab tab={tab} setTab={setTab} />
        {tab === 'Open' ? (
          <OpenBoxTab
            info={props.info}
            detail={detail}
            onRefresh={props.onRefresh}
          />
        ) : (
          <WinBoxTab />
        )}
      </Box>
    </Box>
  );
};

const Tab = (props: { tab: string; setTab: (tab: string) => void }) => {
  const { tab, setTab } = props;
  const { __ } = useLang();
  return (
    <Box
      sx={{
        height: {
          xs: '46px',
          md: '40px',
        },
        mb: {
          xs: 1,
          md: 0,
        },
        display: 'flex',
        fontStyle: 'italic',
      }}
    >
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('Open');
        }}
        sx={{
          background: tab === 'Open' ? getTabBg().background : '',
          borderRadius: '8px 8px 0 0',
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight={700}
          lineHeight={1}
          sx={{ textAlign: 'center' }}
          color={tab === 'Open' ? 'text.primary' : 'text.secondary'}
        >
          {__('Open treasure chest')}
        </Typography>
      </Box>
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('Win');
        }}
        sx={{
          background: tab === 'Win' ? getTabBg().background : '',
          borderRadius: '8px 8px 0 0',
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight={700}
          lineHeight={1}
          color={tab === 'Win' ? 'text.primary' : 'text.secondary'}
        >
          {__('Win commission')}
        </Typography>
      </Box>
    </Box>
  );
};

const MobileTab = (props: { tab: string; setTab: (tab: string) => void }) => {
  const { tab, setTab } = props;
  const { __ } = useLang();

  return (
    <Box mb={2} position="relative">
      <Stack
        direction="row"
        sx={{
          height: '42px',
          textAlign: 'center',
        }}
      ></Stack>

      <Box
        sx={{
          position: 'absolute',
          top: '-6px',
          left: tab === 'Open' ? 0 : '',
          right: tab === 'Win' ? 0 : '',
          width: '100%',
        }}
        onClick={() => {
          setTab(tab === 'Open' ? 'Win' : 'Open');
        }}
      >
        <Box
          position="relative"
          sx={{
            display: 'flex',
            justifyContent: tab === 'Open' ? 'flex-start' : 'flex-end',
          }}
        >
          <img
            src={tab === 'Open' ? IMAGE_ACTIVE_LEFT : IMAGE_ACTIVE_RIGHT}
            alt=""
            style={{ width: '53.6%', height: '48px' }}
          />

          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Stack direction="row" alignItems="center">
              <Box
                flex={1}
                onClick={() => setTab('Open')}
                sx={tab === 'Open' ? { pt: 1, pb: 2.5 } : { pt: 1.5, pb: 2 }}
              >
                <Typography
                  variant="body1"
                  color={tab === 'Open' ? 'text.primary' : 'text.secondary'}
                  fontWeight={tab === 'Open' ? 700 : 500}
                  lineHeight={1}
                >
                  {__('Open treasure chest')}
                </Typography>
              </Box>
              <Box
                flex={1}
                onClick={() => setTab('Win')}
                sx={tab !== 'Open' ? { pt: 1, pb: 2.5 } : { pt: 1.5, pb: 2 }}
              >
                <Typography
                  variant="body1"
                  color={tab !== 'Open' ? 'text.primary' : 'text.secondary'}
                  fontWeight={tab !== 'Open' ? 700 : 500}
                  lineHeight={1}
                >
                  {__('Win commission')}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
