import { INVITE_CODE_KEY } from '@/constants';
import useAuth from '@/hooks/useAuth';
import useLang from '@/hooks/useLang';
import services from '@/services';
import {
  failAccountState,
  isLoginState,
  isOpenForgetPwModalState,
  isOpenLRModalState,
} from '@/stores/auth';
import { ColorfulBg } from '@/styles';
import { customColor } from '@/theme/customColor';
import { SigninType } from '@/types';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import AreaSelect from './AreaSelect';
import CodeInput from './CodeInput';
import PasswordInput from './PasswordInput';

interface Props {
  type: SigninType;
  guid?: string;
  id?: string;
  displayType: 'dialog' | 'page';
}

const LoginForm: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const [areaCode, setAreaCode] = useState<number>(55);
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [inviteCode, setInviteCode] = useState<string>(
    sessionStorage.getItem(INVITE_CODE_KEY) || '',
  );
  const [isPassword, setIsPassword] = useState<boolean>(true);
  const [rememberAp, setRememberAp] = useState<boolean>(true);
  const [isPrivacy, setIsPrivacy] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const { getUserInfo, getWallet } = useAuth();
  const setIsLogin = useSetRecoilState(isLoginState);
  const setIsOpenForgetPwModal = useSetRecoilState(isOpenForgetPwModalState);
  const setIsOpenLRModal = useSetRecoilState(isOpenLRModalState);
  const setFailAccountState = useSetRecoilState(failAccountState);

  const [loading, setLoading] = useState<boolean>(false);
  const { __ } = useLang();

  const isPhone = props.type === SigninType.Phone;
  const isAvaliable =
    // !!code && && !!passwordAgain
    !!password && (isPhone ? !!phone : !!email) && isPrivacy;

  const postLoginRequest = async (params: any) => {
    return await services.auth.login(params);
  };
  const onPrivacy = () => {
    window.open('https://www.br-baby.com/agreement.html', '_blank');
  };
  const handleLogin = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      let params: any = { requestType: 1 };
      if (isPassword) {
        params['password'] = password;
      } else {
        params['code'] = code;
      }

      if (isPhone) {
        params['phone'] = phone;
        params['type'] = 2;
      } else {
        params['email'] = email;
        params['type'] = 1;
      }

      if (inviteCode) {
        params['recommender'] = inviteCode;
      }
      const res = await postLoginRequest(params);
      const token = res.data?.data?.token;
      if (token) {
        localStorage.setItem('access_token', token);
        // 记住账号密码
        if (rememberAp) {
          delete params.recommender;
          localStorage.setItem('rememberAccount', JSON.stringify(params));
        }
        setIsLogin(true);
        // SnackbarUtils.success('Login success');
        getUserInfo();
        getWallet();
        // navigate(-1);
        // setIsOpenSigninModal(false);
        setIsOpenLRModal(false);
        setFailAccountState('');
      } else {
        console.log('phone:', phone);
        if (isPhone) {
          setFailAccountState(phone);
        } else {
          setFailAccountState(email);
        }
      }
    } catch (error) {
      console.log(error);
      if (isPhone) {
        setFailAccountState(phone);
      } else {
        setFailAccountState(email);
      }
    } finally {
      setLoading(false);
      if (isPhone) {
        setFailAccountState(phone);
      } else {
        setFailAccountState(email);
      }
    }
  };

  const handleSendCode = () => {
    if (isPhone) {
      return services.auth.sendSMS({ phone: `+55${phone}`, type: 1 });
    } else {
      return services.auth.sendEmail({ email, type: 1 });
    }
  };

  const AccountInput = {
    [SigninType.Email]: (
      <OutlinedInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={__('Please enter email')}
        size="medium"
        name="account-email-login"
      />
    ),
    [SigninType.Phone]: (
      <OutlinedInput
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        size="medium"
        startAdornment={
          <InputAdornment position="start">
            <AreaSelect code={areaCode} onChangeCode={(v) => setAreaCode(v)} />
            <Box
              sx={{ width: '1px', height: '20px', bgcolor: '#2D3445' }}
            ></Box>
          </InputAdornment>
        }
        type="number"
        name="account-phone-login"
        placeholder={__('Please enter phone number')}
      />
    ),
  };

  return (
    <Box sx={{ pb: { xs: 4, md: 0 } }}>
      <Stack spacing={{ xs: 1.5, md: 2 }}>
        {AccountInput[props.type]}
        {isPassword ? (
          <PasswordInput
            password={password}
            onChangePassword={(v) => setPassword(v)}
          />
        ) : (
          <CodeInput
            code={code}
            onChange={(v) => setCode(v)}
            onSendSMS={handleSendCode}
          />
        )}
        {/* <OutlinedInput
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
          size="medium"
          type="number"
          placeholder={__('Invitation Code (Optional)')}
        /> */}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        my="3px"
      >
        {/* <Button
          variant="text"
          size="small"
          color="info"
          sx={{ fontWeight: 400 }}
          onClick={setIsPassword.toggle}
        >
          {__(!isPassword ? '密码登录' : '验证码登录')}
        </Button> */}
        <Box></Box>
        <Button
          variant="text"
          size="small"
          sx={{ fontWeight: 400, color: 'text.primary' }}
          onClick={() => {
            // if (isMobile) {
            //   navigate('/reset-password');
            // } else {
            //   setIsOpenSigninModal(false);
            //   setIsOpenForgetPwModal(true);
            // }

            setIsOpenLRModal(false);
            setIsOpenForgetPwModal(true);
          }}
        >
          {__('Forgot password?')}
        </Button>
      </Stack>
      {/* <Typography
        variant="body2"
        mt={1.5}
        sx={{ span: { color: 'info.main' } }}
        color="text.disabled"
      >
        {__('login tip').replace(
          '{type}',
          props.type == 'Phone'
            ? __('Mobile phone number')
            : __('email address'),
        )}
      </Typography> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mt: '40px',
        }}
      >
        <Checkbox
          checked={isPrivacy}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
          onChange={(e) => {
            setIsPrivacy(e.target.checked);
          }}
        />
        <Typography
          variant="body2"
          sx={{ span: { color: 'primary.main' } }}
          color="text.secondary"
        >
          {__('pricayTip')}{' '}
          <span
            onClick={() => {
              onPrivacy();
            }}
          >
            {__('pricayLink')}
          </span>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Checkbox
          checked={rememberAp}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
          onChange={(e) => {
            setRememberAp(e.target.checked);
          }}
        />
        <Typography variant="body2" color="text.secondary">
          {__('Remember account password')}
        </Typography>
      </Box>
      <LoadingButton
        variant="contained"
        fullWidth
        disabled={!isAvaliable}
        sx={{
          boxShadow: 'none !important',
          mt: 2,
          borderRadius: '8px',
        }}
        style={{
          backgroundColor: isAvaliable
            ? ColorfulBg()
            : customColor.disable_button_bg,
        }}
        onClick={handleLogin}
        loading={loading}
      >
        {__('登录')}
      </LoadingButton>

      {/* <Typography
        variant="body2"
        mt={1.5}
        sx={{ span: { color: 'info.main' } }}
        color="text.disabled"
      >
        This site is subject to recaptcha and Google <span>
          Privacy Policy
        </span>{' '}
        and <span>Terms Of Service</span> apply.
      </Typography> */}

      {/* <Typography
        variant="body2"
        textAlign="center"
        mt={{ xs: 4, md: 3 }}
        sx={{
          span: { color: 'primary.main' },
          cursor: 'pointer',
        }}
        onClick={() => {
          if (props.displayType === 'dialog') {
            setIsOpenRegisterModal(true);
            setIsOpenSigninModal(false);
          } else {
            navigate('/signup');
          }
        }}
      >
        {__('Not registered?')} <span>{__('Join us')}</span>
      </Typography> */}

      {/* <SocialAuthLogin /> */}
    </Box>
  );
};

export default LoginForm;
