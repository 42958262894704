import IC_QUESTION from '@/assets/icons/icon_question.png';
import BOX_LIGHT_BG from '@/assets/images/box_light_bg.png';
import Center from '@/components/center';
import CommonSkeleton from '@/components/CommonSkeleton';
import EmptyData from '@/components/EmptyData';
import { SnackbarUtils } from '@/components/snackbar/SnackbarProvider';
import useAuth from '@/hooks/useAuth';
import useBoolean from '@/hooks/useBoolean';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import services from '@/services';
import { isOpenBoxNumTipState } from '@/stores/auth';
import { homeTab } from '@/stores/config';
import { ColorfulBg } from '@/styles';
import { HomeTab } from '@/types';
import { RStatus } from '@/types/vip';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VipIcons } from '../Vip/consts';
import NumTipDrawer from './components/NumTipDrawer';
import RewardDialog from './components/RewardDialog';
import RewardItem from './components/RewardItem';
import { VipMenus } from './components/VipMenus';
import VipRewards from './components/VipRewards';
import BG from './images/bg.png';
import BLINDBOX from './images/box.png';

import './index.css';

const BlindBoxMain = () => {
  const navigate = useNavigate();
  const { getWallet, currentVip, user, getUserInfo } = useAuth();
  const [open, setOpen] = useBoolean();
  const [loading, setLoading] = useBoolean();
  const imgRef = useRef<HTMLImageElement>(null);
  const [reward, setReward] = useState<{
    pic: string;
    title: string;
    num: number;
  }>();
  const { __ } = useLang();
  const [openBoxTip, setOpenBoxTip] = useRecoilState(isOpenBoxNumTipState);
  const count = user?.lottery_num ?? 0;

  const handleStart = async () => {
    if (!imgRef.current) {
      return;
    }
    if (loading) {
      return;
    }
    if (count <= 0) {
      return;
    }

    setLoading.on();
    imgRef.current.classList.add('vibrate-3');

    const res = await services.lucky.luckyDraw({
      type_id: 1,
    });

    if (res?.data?.pic) {
      setReward({
        pic: res.data?.pic,
        title: res.data?.title,
        num: res.data?.number || 0,
      });
    }

    setTimeout(() => {
      if (res?.data?.pic) {
        setOpen.on();

        if (res?.handle === 'refresh_money') {
          getWallet();
        }
        getUserInfo();
      } else if (res?.msg) {
        SnackbarUtils.error(res.msg);
      }

      setLoading.off();
      imgRef.current?.classList.remove('vibrate-3');
    }, 2000);
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: { xs: 560, md: '100%' },
          mx: 'auto',
          position: 'relative',
        }}
      >
        <img src={BG} alt="" style={{ width: '100%', height: 'auto' }} />

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            right: 0,
            bottom: '17%',
          }}
        >
          <Box sx={{ py: '12px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <img src={VipIcons[currentVip]} alt="" width={24} height={24} />
              <Typography variant="subtitle1" fontWeight={700}>
                VIP {currentVip}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              onClick={() => {
                setOpenBoxTip(true);
              }}
            >
              <img src={IC_QUESTION} alt="" width={14} height={14} />
              <Typography variant="body2" color={'text.secondary'}>
                {__('How to get the number of draws')}
              </Typography>
            </Stack>
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Stack
              onClick={() => {
                navigate('/winnings');
              }}
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                cursor: 'pointer',
                bgcolor: '#313D4D99',
                py: '8px',
                px: '16px',
                borderTopLeftRadius: 999,
                borderBottomLeftRadius: 999,
              }}
            >
              <Typography mb="1px" textAlign="center">
                {__('Winning Record')}
              </Typography>
              <ChevronRightRoundedIcon />
            </Stack>
          </Stack>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={BLINDBOX}
              ref={imgRef}
              alt=""
              style={{ width: '51.2%', height: 'auto' }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            right: 0,
            bottom: '17%',
            display: 'flex',
            justifyContent: 'center',
          }}
        ></Box>

        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            position: 'absolute',
            width: '100%',
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'linear-gradient(180deg, rgba(31, 26, 46, 0.00) 0%, #1F1A2E 100%)',
            height: '126px',
            borderRadius: 1,
          }}
        ></Box>

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            right: 0,
            bottom: { xs: 4, md: 32 },
          }}
        >
          <Stack spacing={{ xs: 0.5, sm: 1 }} alignItems="center">
            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              {__('box num').replace('[num]', count)}
            </Typography>
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{
                background: count > 0 ? ColorfulBg() : '#333E4F',
                borderRadius: '8px',
                width: { xs: 215, md: 215 },
                height: 40,
                pointerEvents: count > 0 ? 'all' : 'none',
              }}
              onClick={handleStart}
            >
              {__('Start')}
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
      {/* 中奖弹窗 */}
      <RewardDialog
        open={open}
        onClose={setOpen.off}
        pic={reward?.pic}
        title={reward?.title}
        num={reward?.num}
      ></RewardDialog>
      <NumTipDrawer />
    </>
  );
};

const BlindBoxMainMobile = () => {
  const navigate = useNavigate();
  const { getWallet, currentVip, user, getUserInfo } = useAuth();
  const [open, setOpen] = useBoolean();
  const [loading, setLoading] = useBoolean();
  const imgRef = useRef<HTMLImageElement>(null);
  const [reward, setReward] = useState<{
    pic: string;
    title: string;
    num: number;
  }>();
  const { __ } = useLang();
  const [openBoxTip, setOpenBoxTip] = useRecoilState(isOpenBoxNumTipState);
  const count = user?.lottery_num ?? 0;

  const handleStart = async () => {
    if (!imgRef.current) {
      return;
    }
    if (loading) {
      return;
    }
    if (count <= 0) {
      return;
    }

    setLoading.on();
    imgRef.current.classList.add('vibrate-3');

    const res = await services.lucky.luckyDraw({
      type_id: 1,
    });

    if (res?.data?.pic) {
      setReward({
        pic: res.data?.pic,
        title: res.data?.title,
        num: res.data?.number || 0,
      });
    }

    setTimeout(() => {
      if (res?.data?.pic) {
        setOpen.on();

        if (res?.handle === 'refresh_money') {
          getWallet();
        }
        getUserInfo();
      } else if (res?.msg) {
        SnackbarUtils.error(res.msg);
      }

      setLoading.off();
      imgRef.current?.classList.remove('vibrate-3');
    }, 1000);
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: { xs: 560, md: '100%' },
          mx: 'auto',
          position: 'relative',
        }}
      >
        <img src={BG} alt="" style={{ width: '100%', height: 'auto' }} />

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100vw',
            left: 0,
            right: 0,
            bottom: '10%',
            backgroundImage: `url(${BOX_LIGHT_BG})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Box sx={{ pb: '12px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              {/* <img src={VipIcons[currentVip]} alt="" width={24} height={24} /> */}
              {/* <Typography variant="subtitle1" fontWeight={700}>
                VIP {currentVip}
              </Typography> */}
              <Typography variant="subtitle1" fontWeight={700}>
                {__('lucky draw')}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
              onClick={() => {
                setOpenBoxTip(true);
              }}
            >
              <img src={IC_QUESTION} alt="" width={14} height={14} />
              <Typography variant="body2" color={'text.secondary'}>
                {__('How to get the number of draws')}
              </Typography>
            </Stack>
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Stack
              onClick={() => {
                navigate('/winnings');
              }}
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                cursor: 'pointer',
                bgcolor: '#313D4D99',
                py: '8px',
                px: '16px',
                borderTopLeftRadius: 999,
                borderBottomLeftRadius: 999,
              }}
            >
              <Typography mb="1px" textAlign="center">
                {__('Winning Record')}
              </Typography>
              <ChevronRightRoundedIcon />
            </Stack>
          </Stack>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: '50px',
            }}
          >
            <img
              src={BLINDBOX}
              ref={imgRef}
              alt=""
              style={{ width: '51.2%', height: 'auto', marginTop: '-30px' }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            right: 0,
            bottom: '17%',
            display: 'flex',
            justifyContent: 'center',
          }}
        ></Box>

        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            position: 'absolute',
            width: '100%',
            left: 0,
            right: 0,
            bottom: 0,
            background:
              'linear-gradient(180deg, rgba(31, 26, 46, 0.00) 0%, #1F1A2E 100%)',
            height: '126px',
            borderRadius: 1,
          }}
        ></Box>

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            right: 0,
            bottom: { xs: 4, md: 32 },
          }}
        >
          <Stack spacing={{ xs: 0.5, sm: 1 }} alignItems="center">
            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              {__('box num').replace('[num]', count)}
            </Typography>
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{
                background: count > 0 ? ColorfulBg() : '#333E4F',
                borderRadius: '8px',
                width: { xs: 215, md: 215 },
                height: 40,
                pointerEvents: count > 0 ? 'all' : 'none',
              }}
              onClick={handleStart}
            >
              {__('Start')}
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
      {/* 中奖弹窗 */}
      <RewardDialog
        open={open}
        onClose={setOpen.off}
        pic={reward?.pic}
        title={reward?.title}
        num={reward?.num}
      ></RewardDialog>
      <NumTipDrawer />
    </>
  );
};

const BlindBox = () => {
  const { updateVipList, getUserInfo } = useAuth();
  const homeTabValue = useRecoilValue(homeTab);

  useEffect(() => {
    if (homeTabValue === HomeTab.Spinner) {
      updateVipList();
      getUserInfo();
    }
  }, [homeTabValue]);

  return (
    <Box
    // ml={-2}
    // mt={`-${mobileHeaderbarHeight}px`}
    // sx={{
    //   position: 'absolute',
    //   backgroundImage: `url(${BG})`,
    //   backgroundSize: 'contain',
    //   backgroundRepeat: 'no-repeat',
    // }}
    >
      <BlindBoxMainMobile />
      {/* 奖品列表 */}
      <VipRewards></VipRewards>
    </Box>
  );
};

const BlindBoxPc = () => {
  const [active, setActive] = useState<number>(0);
  const { vip } = useAuth();
  const isLoading = vip.status !== RStatus.success;

  const lotteryGifts = vip.data?.[active]?.lottery_gift ?? [];

  const { getVipList } = useAuth();
  useEffect(() => {
    getVipList();
  }, []);

  return (
    <Box sx={{ display: 'flex', py: 2 }}>
      <Box minWidth={200} width={200} mr={2}>
        <VipMenus
          isLoading={isLoading}
          active={active}
          data={vip.data}
          onChange={(level) => setActive(level)}
        />
      </Box>
      <Box sx={{ display: 'flex', flex: 'auto' }}>
        <Box flex="1" mr={2}>
          <BlindBoxMain />
        </Box>
        <Box flex="1">
          <Box mb={2}>
            <Typography variant="subtitle2">Rewards</Typography>
          </Box>
          {isLoading ? (
            <Box>
              <CommonSkeleton />
            </Box>
          ) : lotteryGifts.length ? (
            <Stack spacing={2}>
              {lotteryGifts?.map((item, index) => {
                return <RewardItem key={index} data={item} />;
              })}
            </Stack>
          ) : (
            <Box>
              <Center py={4}>
                <EmptyData />
              </Center>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const BlindBoxPage = () => {
  const isMobile = useResponsive('down', 'md');

  if (isMobile) {
    return <BlindBox />;
  } else {
    return <BlindBoxPc />;
  }
};

export default BlindBoxPage;
