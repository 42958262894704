import { Box } from '@mui/material';
import { Vip } from './vip';

interface Props {}

const VipPage: React.FC<Props> = (props: Props) => {
  return (
    // mr={-2}
    <Box id={'vip'} pb={3}>
      <Vip open noHeader />
    </Box>
  );
};

export default VipPage;
