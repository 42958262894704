import IMAGE_SEARCH from '@/assets/icons/search.png';
import useLang from '@/hooks/useLang';
import { InputAdornment, OutlinedInput } from '@mui/material';

interface Props {
  keyword: string;
  name?: string;
  onChange: (value: string) => void;
}

const KeywordInput: React.FC<Props> = (props: Props) => {
  const { __ } = useLang();
  return (
    <form>
      <OutlinedInput
        size="small"
        value={props.keyword}
        onChange={(e) => props.onChange(e.target.value)}
        fullWidth
        type="text"
        autoComplete="false"
        name={props.name || ''}
        placeholder={__('Search')}
        inputProps={{
          sx: {
            '&::placeholder': {
              color: '#999999',
            },
            py: '8px !important',
            pl: '8px !important',
            height: { xs: 16, md: 24 },
          },
          autoCapitalize: 'none',
        }}
        sx={{
          bgcolor: 'transparent',
          height: {
            xs: 32,
            md: 40,
          },
          borderRadius: '8px',
          '& fieldset': {
            borderColor: '#323D4D !important',
            borderWidth: '0.5px !important',
          },
        }}
        // autoComplete="do-not-autofill"
        // autoComplete="chrome-off"
        startAdornment={
          <InputAdornment position="start">
            <img
              src={IMAGE_SEARCH}
              alt=""
              width={15}
              height={15}
              style={{
                width: 15,
                minWidth: 15,
                height: 15,
                marginRight: '-8px',
              }}
            />
          </InputAdornment>
        }
      ></OutlinedInput>
    </form>
  );
};

export default KeywordInput;
