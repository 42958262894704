import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import AuthTabs from '@/pages/Auth/components/AuthTabs';
import { isOpenForgetPwModalState } from '@/stores/auth';
import { SigninType } from '@/types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import { useRecoilState } from 'recoil';
import ResetPasswordForm from '../login/ResetPasswordForm';

const ForgetPwModal = () => {
  const [isOpen, setIsOpen] = useRecoilState(isOpenForgetPwModalState);
  const { __ } = useLang();
  const isMobile = useResponsive('down', 'md');

  const HeaderView = (
    <Box
      position="relative"
      height={56}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h5" textAlign="center">
        {__('Forgot Password')}
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: 56,
          height: 56,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton color="info" onClick={() => setIsOpen(false)}>
          <CloseIcon sx={{ fontSize: 24, color: '#8B899C' }} />
        </IconButton>
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <Drawer
        anchor={'bottom'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="foget-password"
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
          },
        }}
      >
        <Box
          bgcolor="transparent"
          position="relative"
          style={{
            width: isMobile ? '100vw' : 410,
            height: isMobile ? '80vh' : '100vh',
            overflowY: 'hidden',
          }}
          sx={{ bgcolor: 'card.main' }}
        >
          {HeaderView}
          <Divider sx={{ mb: 1 }} />
          <Box px={2} pb={0.5}>
            <AuthTabs
              emailTab={<ResetPasswordForm type={SigninType.Email} />}
              phoneTab={<ResetPasswordForm type={SigninType.Phone} />}
            />
          </Box>
        </Box>
      </Drawer>
    );
  }

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
        },
      }}
      onClose={() => setIsOpen(false)}
      aria-labelledby="foget-password"
    >
      <Box sx={{ flex: 1 }} bgcolor="background.neutral">
        {HeaderView}
        <Divider sx={{ mb: 1 }} />
        <Box px={2} pb={0.5}>
          <AuthTabs
            emailTab={<ResetPasswordForm type={SigninType.Email} />}
            phoneTab={<ResetPasswordForm type={SigninType.Phone} />}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default ForgetPwModal;
