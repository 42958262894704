import KeywordInput from '@/components/category/KeywordInput';
import Center from '@/components/center';
import CommonSkeleton from '@/components/CommonSkeleton';
import EmptyData from '@/components/EmptyData';
import { HeartbeatMsg } from '@/components/heartbeat-msg/heartbeat-msg';
import HomeBannerV2 from '@/components/home/BannerV2';
import { FlishGameList } from '@/components/home/FlishGameList';
import { GameCard } from '@/components/home/GameList';
import GameProviders from '@/components/home/GameProviders';
import { HomeGameListSmall } from '@/components/home/HomeGameList1';
import { IconImgBgGameList } from '@/components/home/IconImgBgGameList';
import { Media5Dialog } from '@/components/home/Media5Dialog';
import { RecommendProviderGameListMobile2 } from '@/components/home/RecommendProviderGameList';
import HomeTabs from '@/components/home/Tabs';
import useBoolean from '@/hooks/useBoolean';
import { useHomeInvite } from '@/hooks/useHomeInvite';
import { useHomeSign } from '@/hooks/useHomeSignin';
import { useHomeTabs } from '@/hooks/useHomeTabs';
import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import useScrollToTop from '@/hooks/useScrollToTop';
import Footer from '@/layouts/Footer';
import AppDownload from '@/layouts/Header/HeaderDownLoad';
import services from '@/services';
import { isLoginState } from '@/stores/auth';
import {
  allGamesMap,
  homeTab,
  indexCategoryList,
  isDataLoadedState,
  isShowHomeGuide,
} from '@/stores/config';
import palette from '@/theme/palette';
import { HomeTab, MessageShowLevel } from '@/types';
import { Box, Button, Grid, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

const colorConfig = palette('dark');

interface Props {}

const TabHome = (props: { onSwip: (title: string) => void }) => {
  const indexCategoryListValue = useRecoilValue(indexCategoryList);
  const allGamesMapValue = useRecoilValue(allGamesMap);
  const navigate = useNavigate();
  const { __ } = useLang();

  const handleClickMore = (title: string) => () => {
    navigate('/category?type=' + title);
  };

  return (
    <Box>
      {/* show_type为1，顶部一行6个 */}
      {indexCategoryListValue
        .filter((i) => i.show_type === 1 && i.ids.length > 0)
        .map((item, index) => {
          return (
            <Box key={index}>
              <HomeGameListSmall
                title={__(item.title)}
                icon={item?.icon}
                bgImg={item?.bgImg}
                list={item.ids.map((i) => allGamesMapValue[i])}
                onClickMore={() => props.onSwip(item.title)}
              />
            </Box>
          );
        })}
      <Box sx={{ pb: 2 }}>
        <GameProviders />
      </Box>

      {/* show_type为2，图片背景，顶部带icon */}
      {indexCategoryListValue
        .filter((i) => i.show_type === 2 && i.ids.length > 0)
        .map((item, index) => {
          return (
            <Box key={index}>
              <IconImgBgGameList
                title={__(item.title)}
                icon={item?.icon}
                bgImg={item?.bgImg}
                list={item.ids.map((i) => allGamesMapValue[i])}
                onClickMore={handleClickMore(item.title)}
              />
            </Box>
          );
        })}
      {/* show_type为3，两排6个，带loadMore */}
      {/* {indexCategoryListValue
        .filter((i) => i.show_type == 3 && i.ids.length > 0)
        .map((item, index) => {
          return (
            <Box key={index}>
              <HomeGameList2
                title={__(item.title)}
                icon={item?.icon}
                bgImg={item?.bgImg}
                list={item.ids.map((i) => allGamesMapValue[i])}
                onClickMore={handleClickMore(item.title)}
              />
            </Box>
          );
        })} */}

      {/* show_type为4，厂商背景，顶部带icon，有viewAll */}
      {indexCategoryListValue
        .filter((i) => i.show_type === 4 && i.ids.length > 0)
        .map((item, index) => {
          return (
            <Box key={index}>
              {/* <RecommendProviderGameList
                title={__(item.title)}
                icon={item?.icon}
                bgImg={item?.bgImg}
                list={item.ids.map((i) => allGamesMapValue[i])}
                onClickMore={handleClickMore(item.title)}
              /> */}
              <RecommendProviderGameListMobile2
                title={__(item.title)}
                icon={item?.icon}
                bgImg={item?.bgImg}
                list={item.ids.map((i) => allGamesMapValue[i])}
                onClickMore={handleClickMore(item.title)}
              />
            </Box>
          );
        })}
      {/* show_type为5，捕鱼，顶部带icon，有viewAll */}
      {indexCategoryListValue
        .filter((i) => i.show_type === 5 && i.ids.length > 0)
        .map((item, index) => {
          return (
            <Box key={index}>
              <FlishGameList
                title={__(item.title)}
                icon={item?.icon}
                bgImg={item?.bgImg}
                list={item.ids.map((i) => allGamesMapValue[i])}
                onClickMore={handleClickMore(item.title)}
              />
            </Box>
          );
        })}

      {/*<HomeRankList type={RankType.Profit} />*/}
      <Footer />
    </Box>
  );
};

const TabGames = ({ title }: { title: string }) => {
  const indexCategoryListValue = useRecoilValue(indexCategoryList);
  const currentCategory = indexCategoryListValue.find((i) => i.title === title);

  if (!currentCategory || currentCategory.ids.length === 0) {
    return (
      <Center my={10}>
        <EmptyData />
      </Center>
    );
  }

  return <TabGamesList ids={currentCategory.ids} />;
};

const TabGamesList = ({ ids }: { ids: number[] }) => {
  const allGamesMapValue = useRecoilValue(allGamesMap);
  const [keyword, setKeyword] = useState<string>('');
  const { __ } = useLang();

  const [isLoadMore, setIsLoadMore] = useBoolean();
  const [showNum, setShowNum] = useState<number>(18);

  const filterGameList = useMemo(() => {
    const list = ids.map((i) => allGamesMapValue[i]);
    if (!keyword) {
      return list;
    }
    return list.filter((i) => {
      const gameName = i.game_name.toLowerCase();
      const keywordLower = keyword.toLowerCase();
      return gameName.includes(keywordLower);
    });
  }, [ids, keyword, allGamesMapValue]);

  const showList = useMemo(() => {
    if (isLoadMore && showNum >= 18) {
      setShowNum(showNum + 18);
      console.log('showNum:', showNum);
      setIsLoadMore.off();
      return showNum > filterGameList.length
        ? filterGameList
        : filterGameList.slice(0, showNum);
    } else {
      return filterGameList.slice(0, showNum);
    }
  }, [filterGameList, isLoadMore]);

  return (
    <Box>
      <Box mb={2}>
        <KeywordInput keyword={keyword} onChange={(v) => setKeyword(v)} />
      </Box>
      {filterGameList.length > 0 ? (
        <Box pb={2}>
          <Grid container gridRow={1} columns={24} spacing={{ xs: 1.5, sm: 2 }}>
            {showList.map((item, index) => {
              return (
                <Grid item xs={6} sm={6} md={3} key={index}>
                  <GameCard item={item} />
                </Grid>
              );
            })}
          </Grid>
          <Center sx={{ mt: '16px' }}>
            <Typography variant="body2" color={'text.disabled'}>
              {__('Game Num')
                .replace('{num}', showList.length)
                .replace('{all}', filterGameList.length)}
            </Typography>
          </Center>

          {showList.length < filterGameList.length ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                my: 2,
                mb: 6,
              }}
            >
              <Button
                variant="text"
                color="info"
                onClick={setIsLoadMore.on}
                size="small"
                sx={{
                  px: 1.5,
                  py: 1,
                  fontSize: 14,
                  height: 32,
                  borderRadius: 999,
                  bgcolor: `${colorConfig.card.main} !important`,
                  fontWeight: 500,
                }}
              >
                {__('Load More')}
              </Button>
            </Box>
          ) : null}
        </Box>
      ) : (
        <Center my={10}>
          <EmptyData />
        </Center>
      )}
    </Box>
  );
};

const HomePage: React.FC<Props> = (props: Props) => {
  useScrollToTop();
  const { __ } = useLang();
  const swiperRef = useRef<any>();

  const isLogin = useRecoilValue(isLoginState);
  const [isShowDLGuide, setIsShowDLGuide] = useRecoilState(isShowHomeGuide);
  const [indexCategoryListValue, setIndexCategoryListValue] =
    useRecoilState(indexCategoryList);
  const homeTabValue = useRecoilValue(homeTab);

  const [tab, setTab] = useState<string>(__('Lobby'));

  const { tabList } = useHomeTabs();
  const isMobile = useResponsive('down', 'md');

  // 首页显示下载引导
  const isShowDLGuideT = isLogin && isMobile && !isShowDLGuide;
  const location = useLocation();
  useEffect(() => {
    const fetchHisData = async () => {
      const res = await services.config.getGameHistory();

      const { data = [] } = res.data;
      if (data?.length > 0) {
        let indexCategoryListValueT = cloneDeep(indexCategoryListValue);
        let recentI = indexCategoryListValueT.findIndex((cate) => {
          return cate.title === 'Recent' || cate.title === 'recentes';
        });

        if (recentI >= 0) {
          let recentT = cloneDeep(indexCategoryListValueT[recentI]);
          recentT.ids = data;
          indexCategoryListValueT.splice(recentI, 1, recentT);
          setIndexCategoryListValue(indexCategoryListValueT);
        }
      }
    };
    if (isLogin) {
      fetchHisData();
    }
  }, [location]);

  return (
    <Box mx={-2}>
      {/* <Box mt={isShowDLGuideT ? '64px' : 0}>
        <HomeScrollMsg />
      </Box> */}
      {homeTabValue === HomeTab.Home ? (
        <HeartbeatMsg showLevel={MessageShowLevel.首页轮播} />
      ) : null}

      <AppDownload />

      <Box px={2} mt={isShowDLGuideT ? '64px' : 0}>
        <Box my={2} mt={0}>
          <HomeBannerV2 />
        </Box>

        {/* 去掉 */}
        {/* <InviteBox /> */}

        <HomeTabs
          tab={tab}
          tabList={tabList}
          onClickTab={(v, idx) => {
            setTab(v);
            swiperRef?.current?.slideTo(idx);
          }}
        />

        <Swiper
          centeredSlides={true}
          spaceBetween={16}
          className="mySwiper"
          noSwiping
          autoHeight
          allowTouchMove={false}
          onSwiper={(s) => {
            swiperRef.current = s;
          }}
        >
          {tabList.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                {item.title === __('Lobby') ||
                item.title === 'Salão' ||
                item.title === 'Lobby' ? (
                  <TabHome
                    onSwip={(title: string) => {
                      const index = tabList.findIndex((i) => i.title === title);
                      setTab(title);
                      swiperRef?.current?.slideTo(index);
                    }}
                  />
                ) : (
                  <TabGames title={item.title} />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      <Media5Dialog />
    </Box>
  );
};

const HomePageContainer = () => {
  useHomeInvite();
  useHomeSign();

  const isDataLoaded = useRecoilValue(isDataLoadedState);
  if (!isDataLoaded) {
    return (
      <Box mx={{ xs: '-16px', md: 0 }}>
        {/* <CommonSkeleton /> */}
        <CommonSkeleton />
      </Box>
    );
  }

  return <HomePage />;
};

export default HomePageContainer;
