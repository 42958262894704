import CommonSkeleton from '@/components/CommonSkeleton';
import { useActivity, useActivityScrollrMessages } from '@/hooks/useActivity';
import useAuth from '@/hooks/useAuth';
import { InviteCopy } from '@/pages/InviteBoxPage/components';
import { ActivityType } from '@/types';
import { hideScrollbarY } from '@/utils/cssStyles';
import { Box } from '@mui/material';

import { AgentWalletBox } from './AgentWalletBox';
import { OpenAndWinBox } from './OpenAndWinBox';
import { RuleDescription } from './RuleDescription';

import useResponsive from '@/hooks/useResponsive';
import { ScrollMessageList } from './ScrollMessageList';
import { generateInviteUrl } from './utils';

interface Props {}

export const TabInvite = (props: Props) => {
  const { user, isFetchingUser, getWallet, getUserInfo } = useAuth();
  const { loading, detail, refetchDetail, eventInfo } = useActivity(
    ActivityType.邀新活动,
  );
  const { messages } = useActivityScrollrMessages(detail?.type);
  const url = generateInviteUrl(user?.uid);
  const isMobile = useResponsive('down', 'md');

  if (!user || isFetchingUser || (loading && !detail)) {
    return (
      <Box height={'calc(100vh - 156px)'} py={10} px={2}>
        <CommonSkeleton />
      </Box>
    );
  }

  return (
    <Box
      height={'calc(100vh - 156px)'}
      id="invite-scroll-container"
      pb={2}
      sx={{
        ...hideScrollbarY,
      }}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          sm: 'row',
        }}
        justifyContent="center"
      >
        <Box
          width={{
            xs: '100%',
            md: '50%',
          }}
          minWidth="50%"
          boxSizing="border-box"
        >
          <Box>
            <ScrollMessageList list={messages} />
            {!isMobile ? (
              <ScrollMessageList list={messages.reverse()} delay={3} />
            ) : null}
          </Box>
          {/* <InviteFriendsImageBox /> */}
        </Box>
        <Box
          width={{
            xs: '100%',
            md: '50%',
          }}
          minWidth="50%"
          boxSizing="border-box"
        >
          <Box px={2} pt={{ xs: 2, md: 2 }} pb={{ xs: 1, md: 2 }}>
            <InviteCopy value={url} />
          </Box>
          <AgentWalletBox />
        </Box>
      </Box>

      <OpenAndWinBox
        info={eventInfo}
        detail={detail}
        onRefresh={() => {
          refetchDetail();
          getWallet();
          getUserInfo();
        }}
      />
      <Box pb={2}>
        <RuleDescription />
      </Box>
    </Box>
  );
};
