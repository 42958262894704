import useLang from '@/hooks/useLang';
import { depositTabState } from '@/stores/auth';
import { ColorfulBg } from '@/styles';
import { Box, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';

export const DepositTabs = () => {
  const { __ } = useLang();
  const [tab, setTab] = useRecoilState(depositTabState);
  return (
    <Box
      sx={{
        height: '40px',
        my: 2,
        position: 'sticky',
        zIndex: 100,
        top: 50,
        left: 0,
        pl: 0,
        pr: 0,
        mx: '16px',
        display: 'flex',
        bgcolor: 'background.default',
        borderRadius: '8px',
      }}
    >
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('amount');
        }}
        sx={{
          bgcolor: tab === 'amount' ? 'background.neutral' : '',
          border: tab === 'amount' ? `1px solid ${ColorfulBg()}` : '',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="body1"
          fontWeight={700}
          lineHeight={1}
          sx={{ textAlign: 'center' }}
          color={tab === 'amount' ? 'text.primary' : 'text.secondary'}
        >
          {__('Amount Recharge')}
        </Typography>
      </Box>
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setTab('package');
        }}
        sx={{
          bgcolor: tab === 'package' ? 'background.neutral' : '',
          border: tab === 'package' ? `1px solid ${ColorfulBg()}` : '',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="body1"
          fontWeight={700}
          lineHeight={1}
          color={tab === 'package' ? 'text.primary' : 'text.secondary'}
        >
          {__('Package Purchase')}
        </Typography>
      </Box>
    </Box>
  );
};
