import useLang from '@/hooks/useLang';
import useResponsive from '@/hooks/useResponsive';
import DrawerHeder from '@/pages/Me/components/DrawerHeder';
import { isOpenTransferState } from '@/stores/auth';
import { Box, Dialog, Drawer } from '@mui/material';
import { useRecoilState } from 'recoil';
import TransferDrawerConten from './TransferDrawerConten';

interface Props {}

const TransferDrawer = (props: Props) => {
  const { __ } = useLang();
  const [openTransfer, setOpenTransfer] = useRecoilState(isOpenTransferState);

  const isMobile = useResponsive('down', 'md');

  const _onClose = () => {
    setOpenTransfer(false);
  };

  if (isMobile) {
    return (
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={openTransfer}
        onClose={_onClose}
        ModalProps={{
          container: document.getElementById('root') as HTMLElement,
        }}
        PaperProps={{
          sx: {
            borderRadius: '24px 24px 0 0',
          },
        }}
      >
        <Box
          bgcolor="transparent"
          position="relative"
          style={{
            width: isMobile ? '100vw' : 410,
            overflowY: 'hidden',
          }}
          sx={{ bgcolor: 'card.main' }}
        >
          <DrawerHeder title={__('Transfer')} onClose={_onClose} />
          <TransferDrawerConten />
        </Box>
      </Drawer>
    );
  }
  return (
    <Dialog
      open={openTransfer}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '400px',
          borderRadius: '20px 20px 0 0',
        },
      }}
      onClose={_onClose}
      aria-labelledby="sign-in"
    >
      <Box
        bgcolor="transparent"
        position="relative"
        style={{
          width: '400px',
          overflowY: 'hidden',
        }}
        sx={{ bgcolor: 'card.main' }}
      >
        <DrawerHeder title={__('Transfer')} onClose={_onClose} />
        <TransferDrawerConten />
      </Box>
    </Dialog>
  );
};

export default TransferDrawer;
