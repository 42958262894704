// material-ui
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party

// project imports
// import MenuList from "./MenuList";
// import LogoSection from "../LogoSection";
import {
  drawerWidth,
  mobileHeaderbarHeight,
  webHeaderbarHeight,
} from '../../theme';
import LocaleButton from './LocaleButton';
import MenuList from './MenuList';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({
  drawerOpen,
  drawerToggle,
}: {
  drawerOpen: boolean;
  drawerToggle: () => void;
}) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: drawerWidth,
        display: matchUpMd ? 'block' : 'none',
        // width: matchUpMd ? drawerWidth : "auto"
        zIndex: (theme) => theme.zIndex.drawer - 2,
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        open={drawerOpen}
        onClose={drawerToggle}
        // container={() => window.document.body}
        // variant={matchUpMd ? "persistent" : "temporary"}
        variant="persistent"
        anchor="left"
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            overflowY: 'visible',
            background: '#0E0918',
            color: theme.palette.text.primary,
            borderRight: 'none',
            height: `calc(100vh - ${webHeaderbarHeight}px)`,
            [theme.breakpoints.up('md')]: {
              top: matchUpMd ? webHeaderbarHeight : mobileHeaderbarHeight,
            },
          },
        }}
        ModalProps={{
          keepMounted: true,
          container: document.getElementById('root') as HTMLElement,
        }}
        color="inherit"
      >
        {/* <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
            <LogoSection />
          </Box>
        </Box> */}

        <Box px={2} py={1}>
          <MenuList />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
          }}
        >
          <LocaleButton />
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
